<div class="taskForm">
  <form novalidate [formGroup]="createTaskForm" class="md-float-material form-material task-form tw-p-2" action="javascript:">

    <!-- Task information -->
    <div class="row">
      <div class="col-md-2 task-sub-heading tw-mt-4">
        <div class="card-block">
          <div class="form-group form-default">
            <span class="form-bar"></span>
            <label class="float-label field_label">{{'task.new-task.tinformation' | translate }}</label>
          </div>
        </div>
      </div>

      <!-- Task name -->
      <div class="col-md-5 tw-mt-4">
        <div class="card-block">
          <mat-form-field appearance="outline">
            <mat-label>{{'task.task-list.taskname' | translate }}</mat-label>
            <input matInput  placeholder="Task Name *" formControlName="taskName" name="taskName" required>
          </mat-form-field>
        </div>
      </div>

      <!-- Task start date/time -->
      <div class="col-md-5 tw-mt-4">
        <div class="card-block">
          <div class="form-group form-default form-static-label">
            <div class="form-group form-default startEndDt input-group startEndDate-style">
              <div class="input-group-append date-time-dropdown">
                <mat-form-field class="example-full-width dropdown-toggle date-dropdown" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" appearance="outline">
                  <input matInput formControlName="taskStartDate" name="t-start-date" type="text" aria-label="Start Date Time" [(ngModel)]="formattedStartDate"
                   autocomplete="off" required>
                  <mat-label>Start Date Time</mat-label>
                </mat-form-field>
                <div class="dropdown-menu" (click)="keepDropDownOpen($event)">
                  <dl-date-time-picker formControlName="strtDate" name="str-date" maxView="year" minView="hour" startView="day" [(ngModel)]="startDate" (change)="startDateSelected($event)" [selectFilter]="startDatePickerFilter" ></dl-date-time-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2 task-sub-heading"></div>

      <!-- Task related to -->
      <div class="col-md-5 relatedName field_description tw-relative tw-mt-4">
        <div class="form-group form-default" *ngIf="!isRelatedTo">
          <ng-select name="relatedto-module" bindLabel="label" appendTo="body" bindValue="value" material class="custom-select"#relatedTo formControlName="relatedtomodule" [items]="relatedtoarr"
           (focus)="relatedTo.open()" placeholder=" Related To " (change)="getSelectedValue($event)"></ng-select>
          <a class="tooltipicon"><i class="fas fa-question-circle"></i></a>
          <div class="tooltiplabel">{{'tooltiplabel.related_to'| translate}}
            </div>
        </div>

        <div class="form-group form-default" *ngIf="isRelatedTo">
          <ng-select  bindLabel="label" appendTo="body" bindValue="value" name="relatedto-module" material class="custom-select disabledClass" #relatedTo formControlName="relatedtomodule" [items]="relatedtoarr" (focus)="relatedTo.open()"
           placeholder=" Related To " (change)="getSelectedValue($event)" ></ng-select>
        </div>
      </div>
      <!-- Task end date/time -->
      <div class="col-md-5  tw-mt-4">
        <div class="form-group form-default startEndDt input-group startEndDate-style">
          <div class="input-group-append date-time-dropdown">
            <mat-form-field class="example-full-width dropdown-toggle date-dropdown1" type="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" appearance="outline">
              <input matInput formControlName="dueDate" type="text" aria-label="End date" name="due-date"
                [(ngModel)]="formattedEndDate" autocomplete="off" required>
              <mat-label>End Date Time</mat-label>
            </mat-form-field>
            <div class="dropdown-menu" (click)="keepDropDownOpen2($event)">
              <dl-date-time-picker formControlName="enDate" maxView="year" minView="hour" startView="day" name="en-date"
                [(ngModel)]="endDate" [(startDate)]="startDate" (change)="endDateSelected($event)"
                [selectFilter]="endDatePickerFilter"></dl-date-time-picker>
            </div>
          </div>
        </div>
      </div>
            </div>
                    <!-- relatedTo dependancy dropdowns -->
        <div class="row" *ngIf="selected">
          <div [ngSwitch]="selected">

            <!-- project relatedTo -->
            <div *ngSwitchCase="'project'" class="row">
              <div class="col-md-2"></div>
              <div class="col-md-5 relatedName field_description tw-mt-4">
              <div class="form-group form-default">

                <!-- Project dropdown -->
                <ng-select bindLabel="label" appendTo="body" bindValue="value" class="custom-select" material [items]="project"
                  #notproject [ngClass]="viewType === 'project' ? 'disabledClass' : ''" name="projectId"
                  formControlName="projectId" placeholder="Project" (change)="fetchMilestoneList($event)"
                  (focus)="notproject.open()">
                </ng-select>
              </div>
              </div>

              <div class="col-md-5 relatedName field_description tw-mt-4">
              <div class="form-group form-default">

                <!-- Milestone dropdown -->
                <ng-select bindLabel="label" appendTo="body" bindValue="value" class=" custom-select" material
                  [items]="milestone" #nomilestone name="milestonesId" formControlName="milestonesId" placeholder="Milestone"
                  (focus)="nomilestone.open()" customSearchEnabled="true">
                </ng-select>

              </div>
              </div>
            </div>

            <!-- opportunity relatedTo -->
            <div *ngSwitchCase="'opp'" class="row">
              <div class="col-md-2"></div>
              <div class="col-md-5 relatedName field_description tw-mt-4">
              <div class="form-group form-default" *ngIf="!isOpportunity">
                <ng-select bindLabel="label" appendTo="body" bindValue="value" class=" custom-select " material
                  [items]="opportunity" #noopportunity formControlName="opportunitiesId" (focus)="noopportunity.open()"
                  placeholder="Opportunity" name="opp-ids">
                </ng-select>
              </div>
              </div>
              <div class="col-md-5 relatedName field_description tw-mt-4">
              <div class="form-group form-default" *ngIf="isOpportunity">
                <ng-select bindLabel="label" appendTo="body" bindValue="value" class=" custom-select disabledClass" material
                  [items]="opportunity" #opportunityList formControlName="opportunitiesId" name="opp-ids"
                  placeholder="Opportunity" (focus)="opportunityList.open()">
                </ng-select>
              </div>
              </div>
            </div>


            <!-- Lead relatedTo -->
            <div *ngSwitchCase="'leads'" class="row">
              <div class="col-md-2"></div>
              <div class="col-md-5 relatedName field_description tw-mt-4">
              <div class="form-group form-default" *ngIf="!isLead">
                <ng-select bindLabel="label" appendTo="body" bindValue="value" class=" custom-select" material [items]="lead"
                  #noleads formControlName="leadsId" placeholder="Leads" (focus)="noleads.open()" name="leads-id">
                </ng-select>
              </div>
              </div>

              <!-- Lead dropdown -->
              <div class="col-md-5 relatedName field_description tw-mt-4">
              <div class="form-group form-default" *ngIf="isLead">
                <ng-select bindLabel="label" appendTo="body" bindValue="value" class=" custom-select disabledClass" material
                  [items]="lead" #leads formControlName="leadsId" placeholder="Leads" (focus)="leads.open()" name="leads-id">
                </ng-select>
              </div>
              </div>
            </div>


            <!-- Bug relatedTo -->
            <div *ngSwitchCase="'bugs'" class="row">
              <div class="col-md-2"></div>
              <div class="col-md-5 relatedName field_description tw-mt-4">
              <div class="form-group form-default">
                <ng-select bindLabel="label" appendTo="body" bindValue="value" class=" custom-select" material [items]="bug"
                  id="bugId" name="bugId" formControlName="bugId" #bugs placeholder="Bugs"
                  [ngClass]="viewType === 'bugs' ? 'disabledClass' : ''" (focus)="bugs.open()" name="bugs-id">
                </ng-select>
              </div>
              </div>
            </div>

            <!-- goal relatedTo -->
            <div *ngSwitchCase="'goal'" class="row">
              <div class="col-md-2"></div>
              <div class="col-md-5 relatedName field_description tw-mt-4">
              <div class="form-group form-default">
                <ng-select bindLabel="label" appendTo="body" bindValue="value" #goalList class=" custom-select" material
                  [items]="goal" formControlName="goalTrackingId" placeholder="goal" (focus)="goalList.open()"
                  customSearchEnabled="true" name="goals-track-id">
                </ng-select>
              </div>
              </div>
            </div>

            <!-- Client relatedTo -->
            <div *ngSwitchCase="'client'" class="row">
              <div class="col-md-2"></div>
              <div class="col-md-5 relatedName field_description tw-mt-4">
              <div class="form-group form-default">
                <ng-select bindLabel="label" appendTo="body" bindValue="value" class=" custom-select" material [items]="client"
                  formControlName="clientId" id="clientId" name="clientId" placeholder="Client" #clientList
                  customSearchEnabled="true" [ngClass]="viewType === 'client' ? 'disabledClass' : ''"
                  (focus)="clientList.open()" name="client-ids">
                </ng-select>
              </div>
              </div>
            </div>

            <!-- Ticket relatedTo -->
            <div *ngSwitchCase="'ticket'" class="row">
                <div class="col-md-2"></div>
                <div class="col-md-5 relatedName field_description tw-mt-4">
              <div class="form-group form-default">
                <ng-select bindLabel="label" appendTo="body" bindValue="value" customSearchEnabled="true"
                  class=" custom-select " material [items]="ticket" #ticketList id="ticketsId" name="ticketsId"
                  formControlName="ticketsId" placeholder="Tickets" [ngClass]="viewType === 'tickets' ? 'disabledClass' : ''"
                  (focus)="ticketList.open()">
                </ng-select>
              </div>
              </div>
            </div>

          </div>
        </div>
    <div class="row">
      <div class="col-md-2 task-sub-heading"></div>


      <!-- Task Priority -->
      <div class="col-md-5  bot3 tw-mt-4">
        <div class="form-group form-default startEndDt input-group startEndDate-style">
          <div class="input-group-append"
            [ngClass]="selected === 'project'? ' task_hour' : ((selected && selected !== '') ? ' task_hour_other': '') ">
            <div class="form-group form-default">
              <ng-select bindLabel="label" bindValue="value" material class="custom-select"
                [items]="taskPriorityData" [formControl]="createTaskForm.controls['taskPriority']" placeholder=" Task Priority * "
                (change)="getSelectPriority($event)" [required]="true" name="task-pri"
                [ngClass]="selectPriority === true ? 'errorClass' :'noError'"></ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2 task-sub-heading"></div>

      <!-- Task department -->
      <div class="col-md-5 tw-mt-4">
        <div class="form-group form-default field_description tw-relative">
          <ng-select bindLabel="label" appendTo="body" name="deptId" bindValue="value" class=" custom-select" material
            [items]="deptId" #departments placeholder="Departments *" (change)="filterChangeDept($event)"
            (focus)="departments.open()" [formControl]="createTaskForm.controls['deptId']" [required]="true"
            customSearchEnabled="true" [ngClass]="selectDeptIdUpdate === true ? 'errorClass' :'noError'">
          </ng-select>
          <a class="tooltipicon"><i class="fas fa-question-circle"></i></a>
          <div class="tooltiplabel">{{'tooltiplabel.department' | translate }}
          </div>
        </div>
      </div>

      <!-- Task estimated hour -->
      <div class="col-md-5 tw-mt-4">
        <div class="form-group form-default field_description"
          [ngClass]="selected === 'project'? ' task_hour' : ((selected && selected !== '') ? ' task_hour_other': '') "
          id="taskHourCheck">
          <div class="form-group form-default">
            <mat-form-field class="example-full-width" appearance="outline"
              [ngClass]="taskHourFlag ? 'errorClass' :'noError'">
              <input matInput type="number" min="0" placeholder="HH.MM" name="taskHour" formControlName="taskHour"
                (keypress)="isNumberKey($event)" (change)="isTimeRight($event)">
              <mat-label>{{'task.new-task.estimatedhour' | translate }}</mat-label>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <!-- Task status information -->
    <div class="row tw-mt-4">
      <div class="col-md-2 task-sub-heading">
        <div class="tasklabel task">
          <div class="card-tasksection">
            <div class="form-group form-default">
              <span class="form-bar"></span>
              <label class="float-label field_label">{{'task.new-task.status' | translate }}</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Task status -->
      <div class="col-md-5 task tw-mt-4">
        <div class="card-tasksection">
          <div class="form-group form-default tw-relative">
            <ng-select bindLabel="label" appendTo="body" bindValue="value" class=" custom-select" material  [items]="taskStatus"#taskstatusList
              formControlName="taskStatus" name="task-sta" required placeholder="Status*" [ngClass]="selectTaskStatusUpdate === true ? 'errorClass' :'noError'" (change)="filterChangeStatus($event)"(focus)="taskstatusList.open()">
            </ng-select>
            <a class="tooltipicon"><i class="fas fa-question-circle"></i></a>
          <div class="tooltiplabel">{{'tooltiplabel.task_status' | translate }}</div>
          </div>
        </div>
      </div>

      <!-- Task Progress -->
      <div class="col-md-5 task tw-mt-4">
        <div class="card-tasksection progress_tab">
          <div class="form-group form-default form-static-label">
            <div class="form-group form-default ">
              <mat-label>{{'task.new-task.progress' | translate }}</mat-label>
                <mat-slider discrete step="1" [displayWith]="formatLabel" [min]="min" [max]="max">
                    <input matSliderThumb formControlName="taskProgress" />
                </mat-slider>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Task message information -->
    <div class="row tw-mt-4">
      <div class="col-md-2 task-sub-heading task">
        <div class="tasklabel">
          <div class="card-tasksection">
            <div class="form-group form-default">
              <span class="form-bar"></span>
              <label class="float-label field_label">{{'task.new-task.tdescription' | translate }}</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Task description -->
      <div class="col-md-10 task tw-mt-4">
        <div class="card-tasksection task-description-row">
          <div class="form-group form-default form-static-label">
            <textarea appTinymceConfig formControlName="taskDescription" [tinymceSettings]="config" name="taskDescription"></textarea>
          </div>
        </div>
      </div>
    </div>

    <!-- Task participants -->
    <div class="row mb-0 card-block task-participant-Style tw-mt-4">
      <div class="col-md-2 task-sub-heading tw-mt-4">
        <div class="tasklabel p-t-10">
          <div class="form-default">
            <span class="form-bar"></span>
            <label class="float-label field_label">{{'task.new-task.participants' | translate }}</label>
          </div>

        </div>
      </div>
      <div class="col-md-5 task m-t-10 tw-mt-4">
        <div class=" form-default form-static-label">
          <div class="particpants_options tw-relative">
              <mat-radio-group formControlName="permission" name="permissionId" ngModel>
                  <mat-radio-button [value]=1 (change)="radioChange($event)" [checked]="radioValue == 1" name="task-form-myself" required>{{
                    'leads.new-leads.myself' | translate }}</mat-radio-button>
                  <mat-radio-button [value]=2 (change)="radioChange($event)" [checked]="radioValue == 2" name="task-form-everyone" required>{{
                    'leads.new-leads.everyone' | translate }}</mat-radio-button>
                  <mat-radio-button [value]=3 (change)="radioChange($event)" [checked]="radioValue == 3" name="task-form-custome" required>{{
                    'leads.new-leads.custom' | translate }}</mat-radio-button>
                </mat-radio-group>
                <a class="tooltipicon"><i class="fas fa-question-circle"></i></a>
               <div class="tooltiplabel">{{ 'tooltiplabel.participants' | translate }}<br>{{ 'tooltiplabel.participants_Myself' | translate }}<br>{{ 'tooltiplabel.participants_Everyone' | translate }}<br>{{ 'tooltiplabel.participants_Custom' | translate }}</div>
          </div>

              <div class="bottom_dropdown show-participant-dropdown" *ngIf="showParticipants">
                  <ng-select bindLabel="label" appendTo="body" bindValue="value" [items]="dropDownUsers" name="salesRep" material (change)="updateSalesRep()" class="custom-select custom-select-style task-custom-user"
                     multiple="true" formControlName="customParticipants" #salesRep (focus)="salesRep.open()"
                     placeholder="{{ 'opportunity.opportunity-form.selectUsers' | translate }} *" id="salesRep" [ngClass]="salesrepCheck === true ? 'errorClass' :'noError'">
                  </ng-select>
                </div>
        </div>
       </div>
       <!-- Task responsible userd ..-->
       <div class="col-md-5 respUser">
          <div class="form-group form-default field_description">
            <div class="form-group form-default form-static-label tw-mt-4" *ngIf="isEditMode">
              <ng-select bindLabel="label" appendTo="body" bindValue="value" required class=" custom-select" material
                [items]="responsibleUserId" #responsibleid formControlName="responsibleUserId" name="respon-ids" placeholder="Responsible User *"
                (focus)="responsibleid.open()" customSearchEnabled="true" (change)="getSelectUser($event)"
                [ngClass]="selectUserIdUpdate === true ? 'errorClass' :'noError'">
              </ng-select>
            </div>
            <div class="form-group form-default form-static-label tw-relative tw-mt-4" *ngIf="!isEditMode">
              <ng-select [clearable]="false" appendTo="body" bindLabel="label" bindValue="value" required class=" custom-select"
                material [items]="responsibleUserId" #responsibleid formControlName="responsibleUserId"
                placeholder="Responsible User *" (focus)="responsibleid.open()" customSearchEnabled="true"
                (change)="getSelectUser($event)" name="respon-ids" [ngClass]="selectUserIdUpdate === true ? 'errorClass' :'noError'"
                [(ngModel)]="responsibleUserIdB">
              </ng-select>
              <a class="tooltipicon"><i class="fas fa-question-circle"></i></a>
              <div class="tooltiplabel">{{'tooltiplabel.responsible_user' | translate }}
              </div>
            </div>
          </div>
        </div>
    </div>

      <hr class="tw-mt-8">

    <!-- Task custom fields -->
    <div *ngIf="isCustomFields" class="tw-p-2 row">
      <div class="col-md-2 task-sub-heading">
        <div class="card-block">
          <div class="form-group form-default m-b-5">
            <span class="form-bar"></span>
            <label class="float-label field_label">Custom Fields</label>
          </div>
        </div>
      </div>
      <div *ngIf="isEditMode && showCustomFields" class="col-md-10 tw-mt-4">
        <app-common-custom-fields [customFieldData] = "customFieldValue" [fieldsArray]="customfieldsArray" (getcustomeFields)="getValuesOfCustomField($event)"></app-common-custom-fields>
      </div>
      <div *ngIf="!isEditMode" class="col-md-10 tw-mt-4">
        <app-common-custom-fields [customFieldData] = "customFieldValue" [fieldsArray]="customfieldsArray" (getcustomeFields)="getValuesOfCustomField($event)"></app-common-custom-fields>
      </div>
    </div>

    <!-- Task button -->
    <div class="row">
      <div class="col-md-12" align="right">
        <div class="card-block m-b-15">
          <button mat-raised-button *ngIf="!isEdit" name="submitBtn"  (click)="saveTask()" class="btn-submit form-button-40"
            color="btn-createemployee">{{'task.new-task.createtask'
            | translate }}</button>
          <button mat-raised-button *ngIf="isEdit" name="editTask" (click)="updateTask()" class="btn-submit form-button-40">{{'task.new-task.updatetask'
            | translate }}</button>
          <button mat-raised-button  type="reset" (click)="cancelForm()" class="btn-cancel form-button-40" name="cancelTask">Cancel</button>
        </div>
      </div>
    </div>

  </form>

</div>
