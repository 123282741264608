import { DateButton, DlDateTimePickerChange } from 'angular-bootstrap-datetimepicker';
import { Component, Injector, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Select2Data, Select2Option } from 'ng-select2-component';
import { NgOption, NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { EStatusCode, IResponse} from '../../../service/Utils/Interfaces.class';
import { MatTabGroup } from '@angular/material/tabs';
import { AppComponentBase } from '../../shared/AppComponentBase';
import { TaskDetailComponent } from '../task-detail/task-detail.component';
import { COMMON, Common, ToastMessages } from "../../../service/constant";
import {RemoveUnderscorePipe} from "../../shared/pipes/remove-underscore-filter.pipe";
import moment from 'moment';
import {unitOfTime} from 'moment';
import { TaskService } from '../../../service/task.service';
import { LeadsService } from '../../../service/leads.service';
import { ProjectService } from '../../../service/project.service';
import { MailboxService } from '../../mailbox/mailbox-service/mailbox.service';
import { DashboardService } from '../../../service/dashboard.service';
import { OpportunitiesService } from '../../../service/opportunities.service';
import { TicketsService } from '../../../service/tickets.service';
import { ClientService } from '../../../service/client.service';
import { BugsService } from '../../../service/bugs.service';
import { SettingsService } from '../../../service/settings.service';
import { ReleaseNoteService } from '../../../service/release-note.service';
import { TinyMceConfigComponent } from '../../tinymce-config';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { CommonCustomFieldsComponent } from '../../shared/common-custom-fields/common-custom-fields.component';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { TinymceConfigDirective } from '../../shared/custom-directives/tinymce-config.directive';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgIf, NgSwitch, NgSwitchCase, NgClass } from '@angular/common';
import { DlDateTimePickerComponent } from '../../../../../../libs/third-party-deprecated/src/lib/angular-bootstrap-datetimepicker/dl-date-time-picker/dl-date-time-picker.component';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'app-task-form',
    templateUrl: './task-form.component.html',
    styleUrls: ['./task-form.component.scss'],
    standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, DlDateTimePickerComponent, NgIf, NgSelectModule, NgSwitch, NgSwitchCase, NgClass, ExtendedModule, MatSlider, TinymceConfigDirective, MatRadioGroup, MatRadioButton, CommonCustomFieldsComponent, MatButton, TranslateModule, MatSliderThumb]
})
export class TaskFormComponent extends AppComponentBase implements OnInit {
  private readonly subscriptions: Subscription[] = [];

  @Input() viewType: string;
  @Input() moduleId: number;
  @Input() projectId: number;
  @Input() tabGroup: MatTabGroup;
  @Output() changedTabLabel = new EventEmitter<string>();
  @Output() calculateLeadCount = new EventEmitter<void>();
  @Output() calculateCount = new EventEmitter<void>();
  @Output() detailCount = new EventEmitter<void>();
  @Output() projectDetailCount = new EventEmitter<void>();
  @ViewChild('picker') picker;
  @ViewChild('duepicker') duepicker;
  @Input() lockStatus: number;
  @Output()  isUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() ticketDetailCount = new EventEmitter<void>();
  @Output() isNavigate: EventEmitter<boolean> = new EventEmitter<boolean>();
  today = new Date();

  // Start and EndDate UTC patching
  StartDateValue: any;
  EndDateValue: any;

  open() {
    this.picker.open();
    this.duepicker.open();
  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    if (value > 100) {
      return Math.round(value / 100);
    }
    return value;
  }
  public selected;
  public selectPriority : boolean = false;
  participantsList = '';
  salesrepCheck : boolean = false;
  project: NgOption = [];
  projectarr: Array<object> = [];
  projects: Select2Data = [];
  isOpportunity: boolean;
  milestone: NgOption = [];
  milestonearr: Array<object> = [];
  responsibleUserId: NgOption = [];
  responsibleUserIdB:any;
  responsibleUserIDN:string;
  responsiblearr: Array<object> = [];
  deptArr: NgOption = [];
  radioValue: number;
  submit: boolean = false;
  showParticipants: boolean = false;
  color = 'warn';
  value = 50;
  responsibleUser: number;
  createTaskForm: FormGroup;
  update: boolean = true;
  fileName: any;
  event: string;
  data: string;
  departmentIds = [];
  public sel: string = 'Related To';

  selectUserIdUpdate:boolean = false;
  selectDeptIdUpdate:boolean = false;
  selectTaskStatusUpdate:boolean = false;
  isEdit: boolean = false;
  public taskrelateselect;
  submitted = false;
  opportunity: NgOption = [];
  deptId: NgOption = [];
  opportunityarr: Array<object> = [];
  bug: NgOption = [];
  bugarr: Array<object> = [];
  relatedtoarr: NgOption = [];
  taskPriorityData: NgOption = [];
  taskStatus: NgOption = [];
  goal: NgOption = [];
  goalarr: Array<object> = [];
  lead: NgOption = [];
  leadarr: Array<object> = [];
  client: NgOption = [];
  clientarr: Array<object> = [];
  ticket: NgOption = [];
  ticketarr: Array<object> = [];
  usersList1: NgOption = [];
  max = 100;
  min = 0;
  start = 0;
  limit = 15;
  searchValue: string;
  pageSizeArray = [15, 50, 100];
  pageSize = 15;
  showMessage: boolean = false;
  toResult: string;
  showResults = 0;
  dataSource = new MatTableDataSource<any>();
  tasks = [];
  isLead: boolean;
  isproject: boolean;
  isRelatedTo: boolean;
  showResultLabel: boolean = false;
  allUsers: Array<object> = [];
  usersData: Select2Option[] = [];
  strNew: string = 'New Task';
  strUpdate: string = 'Update Task';
  datelimit : Date;
  private subscription: Subscription;
  public editTaskId: number;
  public isEditMode: boolean;
  private clientDetailId: number;
  startValue: string;
  customfieldsArray: Array<object>;
  isCustomFields: boolean = false;
  public customFieldValue: Array<object> = [];
  customFieldData: Array<object> = [];
  public showCustomFields: boolean = false;
  taskData: any ;
  deptIdCheck : number;
  removeUnderScorePipe = new RemoveUnderscorePipe();
  taskHourFlag = false;
  startDate: Date;
  endDate: Date;
  minDuration = 0;
  formattedEndDate:string;
  formattedStartDate:string;
  _isStartPickerOpen = false;
  private _isEndPickerOpen = false;
  disablePastDates = true;
  closeDD:any;
  config: any;
  tenant_name: any;
  imgListArr = [];
  imgCount: number = 0;
  imgArr = [];
  @ViewChild("relatedTo") ngSelect_1: NgSelectComponent;
  @ViewChild("notproject") ngSelect_2: NgSelectComponent;
  @ViewChild("nomilestone") ngSelect_3: NgSelectComponent;
  @ViewChild("projectList") ngSelect_4: NgSelectComponent;
  @ViewChild("milestoneList") ngSelect_5: NgSelectComponent;
  @ViewChild("noopportunity") ngSelect_6: NgSelectComponent;
  @ViewChild("opportunityList") ngSelect_7: NgSelectComponent;
  @ViewChild("noleads") ngSelect_8: NgSelectComponent;
  @ViewChild("leads") ngSelect_9: NgSelectComponent;
  @ViewChild("bugs") ngSelect_10: NgSelectComponent;
  @ViewChild("goalList") ngSelect_11: NgSelectComponent;
  @ViewChild("clientList") ngSelect_12: NgSelectComponent;
  @ViewChild("ticketList") ngSelect_13: NgSelectComponent;
  @ViewChild("responsibleid") ngSelect_14: NgSelectComponent;
  @ViewChild("priorityNg") ngSelect_15: NgSelectComponent;
  @ViewChild("departments") ngSelect_16: NgSelectComponent;
  @ViewChild("taskstatusList") ngSelect_17: NgSelectComponent;
  @ViewChild("salesRep") ngSelect_18: NgSelectComponent;
  participantsArr = [];
  
  private onScroll = (event: any) => {
    this.closeDD = (this.ngSelect_1 && this.ngSelect_1.isOpen) ? this.ngSelect_1 : (this.ngSelect_2 && this.ngSelect_2.isOpen) ? this.ngSelect_2 : (this.ngSelect_3 && this.ngSelect_3.isOpen) ? this.ngSelect_3 :
    (this.ngSelect_4 && this.ngSelect_4.isOpen) ? this.ngSelect_4 : (this.ngSelect_5 && this.ngSelect_5.isOpen) ? this.ngSelect_5 : (this.ngSelect_6 && this.ngSelect_6.isOpen) ? this.ngSelect_6 :
    (this.ngSelect_7 && this.ngSelect_7.isOpen) ? this.ngSelect_7 : (this.ngSelect_8 && this.ngSelect_8.isOpen) ? this.ngSelect_8 : (this.ngSelect_9 && this.ngSelect_9.isOpen) ? this.ngSelect_9 :
    (this.ngSelect_10 && this.ngSelect_10.isOpen) ? this.ngSelect_10 : (this.ngSelect_11 && this.ngSelect_11.isOpen) ? this.ngSelect_11 : (this.ngSelect_12 && this.ngSelect_12.isOpen) ? this.ngSelect_12 :
    (this.ngSelect_13 && this.ngSelect_13.isOpen) ? this.ngSelect_13 : (this.ngSelect_14 && this.ngSelect_14.isOpen) ? this.ngSelect_14 : (this.ngSelect_15 && this.ngSelect_15.isOpen) ? this.ngSelect_15 :
    (this.ngSelect_16 && this.ngSelect_16.isOpen) ? this.ngSelect_16 : (this.ngSelect_17 && this.ngSelect_17.isOpen) ? this.ngSelect_17 : (this.ngSelect_18 && this.ngSelect_18.isOpen) ? this.ngSelect_18 : '';
    if (this.closeDD) {
        const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
        if (isScrollingInScrollHost) { return; }
        this.closeDD.close();
    }
  }


  constructor(injector: Injector, public taskService:TaskService, public leadsService:LeadsService,
    public projectService:ProjectService, public mailboxService:MailboxService, public dashboardService:DashboardService,
    public opportunitiesService:OpportunitiesService, public ticketsService:TicketsService, public clientService:ClientService,
    private releaseNoteService: ReleaseNoteService,
    public bugsService:BugsService, public settingService:SettingsService, public mceConfig: TinyMceConfigComponent) {
    super(injector);
    this.getCustomFields();
    this.isEditMode = false;
    const updateTasksEven : Subscription = this.subscription = this.taskService.updateTasksEventListener.subscribe(
      (val: number) => {
        if (val) {
          this.editTaskId = val;
          this.isEditMode = true;
          this.taskService.updateTasksEventListener.next(0);
        }
      }
    );
    this.subscriptions.push(updateTasksEven);
  }

  ngOnInit() {

    window.addEventListener('scroll', this.onScroll, true);
    if (this.viewType === 'client') {
      this.clientDetailId = this.moduleId;
      this.selected = 'client';
      this.relatedtoarr = [
        {
          "value": "client",
          "label": "Client"
        }
      ];
    } else if (this.viewType === 'bugs') {
      this.selected = 'bugs';
      this.relatedtoarr = [
        {
          "value": "bugs",
          "label": "Bugs"
        }
      ];
    } else {
      this.relatedtoarr = [
        {
          "value": "project",
          "label": "Project"
        },
        {
          "value": "opp",
          "label": "Opportunities"
        },
        {
          "value": "bugs",
          "label": "Bugs"
        },
        {
          "value": "leads",
          "label": "Leads"
        },
        {
          "value": "goal",
          "label": "Goal Tracking"
        },
        {
          "value": "client",
          "label": "Client"
        },
        {
          "value": "ticket",
          "label": "Ticket"
        }

      ];
    }

    this.taskStatus = [
      {
        "value": "in_progress",
        "label": "In Progress",
      },
      {
        "value": "not_started",
        "label": "Not Started"
      },
      {
        "value": "completed",
        "label": "Completed"
      },
      {
        "value": "on_hold",
        "label": "On Hold"
      },
      {
        "value": "waiting_for_someone",
        "label": "Waiting for Someone"
      },
      {
        "value": "cancel",
        "label": "Cancel"
      }

    ];

    this.taskPriorityData = [
      {
        "value": "low",
        "label": "Low",
      },
      {
        "value": "medium",
        "label": "Medium"
      },
      {
        "value": "high",
        "label": "High"
      },
      {
        "value": "urgent",
        "label": "Urgent"
      }
    ];

    this.startValue = "in_progress";
    this.getResponsibleUserList();
    this.getValidations();
    this.projectList();

    if (this.isEditMode) {
      setTimeout(() => {
        this.getTaskDetails(this.editTaskId);
      }, 100);
      this.changedTabLabel.emit(this.strUpdate);
    } else {
      // Patching system date
      this.StartDateValue = this.ConvertUtc(new Date())
      this.formattedStartDate = this.StartDateValue;
      this.EndDateValue = this.ConvertUtc(new Date())
      this.formattedEndDate = this.EndDateValue;

      this.radioValue = 1;
      this.changedTabLabel.emit(this.strNew);
      this.responsibleUserIdB =  this.configService.userId;
      this.responsibleUserIDN = localStorage.getItem('fullname');
    }
    this.onrelatedSelected(this.event);
    this.opportunityList();
    this.bugList();
    this.goalList();
    this.leadsList();
    this.clientsList();
    this.ticketList();
    this.getDepartmentList();
    this.employeedropdownList();
    this.isRelatedTo = this.viewType == 'opportunities' || this.viewType == 'leads' || this.viewType == 'project' || this.viewType === 'client' || this.viewType === 'bugs' || this.viewType === 'tickets';
    if (this.viewType == 'opportunities') {

      this.isOpportunity = true;

      setTimeout(() => {
        this.getOpportunityDetails();
      }, 500);
    }
    else {

      this.isOpportunity = false;
    }
    if (this.viewType == 'leads') {
      this.isLead = true;
      setTimeout(() => {
        this.getLeadsDetails();
      }, 1000);
    }
    else {
      this.isLead = false;
    }

    if (this.viewType === 'project') {
      this.isproject = true;
      setTimeout(() => {
        this.getProjectDetails();
        this.getMilestoneList(this.projectId);
        this.getResponsibleUserList();
      }, 1000);
    }
    else {
      this.isproject = false;
    }
    this.datelimit = this.today;
    this.createTaskForm.controls['relatedtomodule'].reset();
    this.createTaskForm.controls['projectId'].reset();
    this.createTaskForm.controls['milestonesId'].reset();
    this.createTaskForm.controls['opportunitiesId'].reset();
    this.createTaskForm.controls['leadsId'].reset();
    this.createTaskForm.controls['bugId'].reset();
    this.createTaskForm.controls['goalTrackingId'].reset();
    this.createTaskForm.controls['clientId'].reset();
    this.createTaskForm.controls['ticketsId'].reset();
    this.createTaskForm.controls['responsibleUserId'].reset();
    this.createTaskForm.controls['deptId'].reset();
    this.createTaskForm.controls['customParticipants'].reset();
    this.createTaskForm.controls['taskPriority'].reset();
    this.createTaskForm.patchValue({
      taskStatus : this.taskStatus[1]['value'],
      taskPriority : this.taskPriorityData[0]['value']
    })
    this.tenant_name = this.configService.tenantName;
    this.getTinymceConfig();
  }

  getTinymceConfig() {
    this.config = this.mceConfig.getTinyMCEConfig(this.tenant_name);
  }

  ngDoCheck() {
    if (this.clientDetailId !== this.moduleId && this.viewType === 'client') {
      this.clientDetailId = this.moduleId;
      this.createTaskForm.patchValue({
        relatedtomodule: 'client',
        clientId: this.moduleId
      });
    }
  }
  ngOnDestroy() {
    this.participantsArr = [];
    window.removeEventListener('scroll', this.onScroll, true);
    this.isEditMode = false;
    this.changedTabLabel.emit(this.strNew);
    this.subscription.unsubscribe();
    this.dropDownUsers = this.inActiveUsers = this.upInActiveUsers = [];
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }

  keepDropDownOpen(event: Event) {
    this._isStartPickerOpen = true;
    event.stopPropagation();
  }

  startDateSelected(event) {
    if (event.value) {
      if (this._isStartPickerOpen) {
        document.querySelector('.date-time-dropdown.show').classList.remove('show');
        document.querySelector('.dropdown-menu.show').classList.remove('show');
        this.formattedStartDate = moment(this.startDate).format('MM/DD/YYYY, hh:mm A');
        this.formattedEndDate = undefined;
      }
    }
  }

  startDatePickerFilter = (dateButton: DateButton, viewName: string) => {
    return true;
  }

  keepDropDownOpen2(event: Event) {
    this._isEndPickerOpen = true;
    event.stopPropagation();
  }

  endDateSelected(event: DlDateTimePickerChange<Date>) {
    if (this._isEndPickerOpen && event.value) {
      document.querySelector('.date-time-dropdown.show').classList.remove('show');
      document.querySelector('.dropdown-menu.show').classList.remove('show');
      this.formattedEndDate = moment(this.endDate).format('MM/DD/YYYY, hh:mm A');
    }
  }

  endDatePickerFilter = (dateButton: DateButton, viewName: string) => {
    const now = moment().startOf(viewName as unitOfTime.StartOf).valueOf();
    const startTime = (this.startDate ? moment(this.startDate).add(this.minDuration, 'minute').startOf(viewName as unitOfTime.StartOf).valueOf()
      : Number.MIN_SAFE_INTEGER);

    return this.disablePastDates
      ? dateButton.value >= startTime
      : dateButton.value <= startTime;
  }

  onrelatedSelected(val) {
    this.taskrelateselect = val;
  }

  // Responsible User  list service
  getResponsibleUserList() {
    const responsibleUseAList : Subscription = this.taskService.responsibleUserList().subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        var myObj = {};
        res.data.forEach(element => {
          myObj = {'value': element.userId, 'label': element.fullname};
          this.responsiblearr.push(myObj);
          if (element.isOwner == 1) {
            this.responsibleUser = element.userId;
          }
        });
        this.responsibleUserId = this.responsiblearr;
      }
    });
    this.subscriptions.push(responsibleUseAList);
  }

  // Department list service
  getDepartmentList() {
    const departList : Subscription = this.taskService.departmentList().subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        var myObj = {};
        res.data.forEach(element => {
          myObj = {'value': element.deptId, 'label': element.name};
          this.departmentIds.push(element.deptId);
          this.deptArr.push(myObj);
        });
        this.deptId = this.deptArr;
      }
    });
    this.subscriptions.push(departList);
  }

  fetchMilestoneList(event) {
    this.milestone = [];
    this.milestonearr = [];
    this.createTaskForm.controls['milestonesId'].reset();

    if (event == undefined) {
      event = 0;
    } else {
      const milestoneListbyProj : Subscription = this.taskService.milestoneListbyProjectId(event.value).subscribe((res: any) => {
        if (res.info.code === EStatusCode.OK) {
          var myObj = {};
          res.data.forEach(element => {
            myObj = {'value': element.milestonesId, 'label': element.milestoneName};
            this.milestonearr.push(myObj);
          });
          this.milestone = this.milestonearr;
        }
      });
      this.subscriptions.push(milestoneListbyProj);
    }
  }

  getMilestoneList(event) {
    if (event == undefined) {
      event = 0;
    } else {
      const milestoneListbyPro : Subscription = this.taskService.milestoneListbyProjectId(event).subscribe((res: any) => {
        if (res.info.code === EStatusCode.OK) {
          var myObj = {};
          res.data.forEach(element => {
            myObj = {'value': element.milestonesId, 'label': element.milestoneName};
            this.milestonearr.push(myObj);
          });
          this.milestone = this.milestonearr;
          if(this.milestone.length>0){
            const milestoneValue = this.milestone.find(x => x.value === this.createTaskForm.value.milestonesId)
            if(milestoneValue){
            this.createTaskForm.patchValue({
              milestonesId : milestoneValue.value
            })
           }          
           }
        }
      });
      this.subscriptions.push(milestoneListbyPro);
    }
  }

  getOpportunityDetails() {
    this.createTaskForm.patchValue({
      relatedtomodule: 'opp',
      opportunitiesId: Number(this.moduleId),
    });
  }


  getLeadsDetails() {
    const detailsABY : Subscription = this.leadsService.detailsById(this.moduleId['leadsId']).subscribe((res: any) => {
      this.createTaskForm.patchValue({
        relatedtomodule: 'leads',
        leadsId: Number(this.moduleId['leadsId']),
      });
    });
    this.subscriptions.push(detailsABY);
  }

  getProjectDetails() {
    const getProjectByA : Subscription = this.projectService.getProjectById(this.projectId).subscribe((res: any) => {
      this.createTaskForm.patchValue({
        relatedtomodule: 'project',
        projectId: Number(this.projectId)
      });
      !this.isEditMode && this.createTaskForm.patchValue({
        responsibleUserId: this.responsibleUser
      });
    });
    this.subscriptions.push(getProjectByA);
  }

  employeedropdownList() {
    const EmployesDDLi : Subscription = this.taskService.EmployesDDList().subscribe((SalesPersonRes: any) => {
      if (SalesPersonRes.info.code === EStatusCode.OK) {
        var myUsersObj = {};
        SalesPersonRes.data.forEach(userObj => {
          myUsersObj = {'value': userObj.userId, 'label': userObj.fullname};
          this.dropArr.push(myUsersObj);
        });
        if (this.dropDownUsers.length > 0) {
          this.dropDownUsers = this.dropArr.concat(this.dropDownUsers);
        } else {
          this.dropDownUsers = this.dropArr
        }
        if(this.participantsArr.length){
          this.createTaskForm.patchValue({
            customParticipants: this.participantsArr
          })
        }
      }
    });
    this.subscriptions.push(EmployesDDLi);
  }

  getSelectedValue(event) {
    this.selected = event ? event.value: '';

    if (event) {
      this.createTaskForm.controls['projectId'].reset();
      this.createTaskForm.controls['milestonesId'].reset();
      this.createTaskForm.controls['opportunitiesId'].reset();
      this.createTaskForm.controls['leadsId'].reset();
      this.createTaskForm.controls['bugId'].reset();
      this.createTaskForm.controls['goalTrackingId'].reset();
      this.createTaskForm.controls['clientId'].reset();
      this.createTaskForm.controls['ticketsId'].reset();
    }
  }

  getSelectPriority(event) {
    this.selectPriority = event === undefined;
  }

  updateSalesRep() {
    this.salesrepCheck = false;
    let users = this.createTaskForm.value.customParticipants;
    if ( (users.length > 0) && (this.upInActiveUsers.length > 0) ) {
      this.checkInactiveUsers(users);
    } else if (this.upInActiveUsers) {
      this.upInActiveUsers = [];
      this.dropDownUsers = this.dropArr;
    }
  }

  // project List service
  projectList() {
    const projectAList : Subscription = this.mailboxService.projectList().subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        var myObj = {};
        res.data.forEach(element => {
          myObj = {'value': element.projectId, 'label': element.clientName? element.clientName +' - '+ element.projectName: element.projectName };
          this.projectarr.push(myObj);
        });
        this.project = this.projectarr;

        if (this.viewType === 'project') {
          this.selected = 'project';
          this.createTaskForm.patchValue({relatedtomodule: 'project', bugId: this.moduleId});
        }
      }
    });
    this.subscriptions.push(projectAList);
  }

  // opportunity List service
  opportunityList() {
    const opportunityLst : Subscription =  this.mailboxService.opportunityList().subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        var myObj = {};
        res.data.forEach(element => {
          myObj = {'value': element.opportunitiesId, 'label': element.clientName? element.clientName +' - '+ element.opportunityName :element.opportunityName};
          this.opportunityarr.push(myObj);
        });
        this.opportunity = this.opportunityarr;
      }
    });
    this.subscriptions.push(opportunityLst);
  }

  // Bugs List service
  bugList() {
    const bugLista : Subscription = this.taskService.bugList().subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        var myObj = {};
        res.data.forEach(element => {
          myObj = {'value': element.bugId, 'label': element.clientName ? (element.clientName + ' - ' + element.bugTitle) : element.bugTitle};
          this.bugarr.push(myObj);
        });
        this.bug = this.bugarr;
        if (this.viewType === 'bugs') {
            this.createTaskForm.patchValue({relatedtomodule: 'bugs', bugId: this.moduleId});
        }
      }
    });
    this.subscriptions.push(bugLista);
  }

  // Goal Tracking List service
  goalList() {
    const goalLista : Subscription =  this.taskService.goalList().subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        var myObj = {};
        res.data.forEach(element => {
          myObj = {'value': element.goalTrackingId, 'label': element.subject};
          this.goalarr.push(myObj);
        });
        this.goal = this.goalarr;
      }
    });
    this.subscriptions.push(goalLista);
  }

  // Leads List service
  leadsList() {
    const leadsLista : Subscription = this.taskService.leadsList().subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        var myObj = {};
        res.data.forEach(element => {
          myObj = {'value': element.leadsId, 'label': element.leadName};
          this.leadarr.push(myObj);
        });
        this.lead = this.leadarr;
      }
    });
    this.subscriptions.push(leadsLista);
  }

  // Clients List service
  clientsList() {
    const clientLista : Subscription = this.taskService.clientList().subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        var myObj = {};
        res.data.forEach(element => {
          myObj = {'value': element.clientId, 'label': element.locationName};
          this.clientarr.push(myObj);
        });
        this.client = this.clientarr;
        if (this.viewType === 'client') {
            this.createTaskForm.patchValue({relatedtomodule: 'client', clientId: this.moduleId});
        }
      }
    });
    this.subscriptions.push(clientLista);
  }

  // Tickets List service
  ticketList() {
    const ticketDDLista : Subscription = this.mailboxService.ticketDDList().subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        var myObj = {};
        res.data.forEach(element => {
          myObj = {'value': element.ticketsId, 'label': element.clientName? element.clientName +' - '+ element.subject + '(' + element.ticketCode + ')': element.subject + '(' + element.ticketCode + ')'};
          this.ticketarr.push(myObj);
        });
        this.ticket = this.ticketarr;
        if (this.viewType == 'tickets') {
          this.selected = 'ticket';
          this.createTaskForm.patchValue({relatedtomodule: 'ticket', ticketsId: this.moduleId});
        }
      }
    });
    this.subscriptions.push(ticketDDLista);
  }

  cancelForm() {
    this.createTaskForm.reset();
    this.tabGroup.selectedIndex = 0;
  }

  radioChange(event) {
    this.showParticipants = event.value === 3;
  }

  getValidations() {
    this.createTaskForm = this.fb.group({
      taskName: ['', Validators.compose([Validators.pattern(COMMON.EMPTY_VALUE), Validators.required])],
      projectId: [''],
      milestonesId: [''],
      deptId: ['', Validators.required],
      opportunitiesId: [''],
      relatedtomodule: [''],
      leadsId: [''],
      clientId: [''],
      ticketsId: [''],
      bugId: [''],
      goalTrackingId: [''],
      responsibleUserId: ['', Validators.required],
      taskStartDate: ['', Validators.required],
      strtDate: [''],
      dueDate: ['', Validators.required],
      enDate: [''],
      taskHour: [''],
      taskStatus: ['', Validators.required],
      taskProgress: [''],
      taskDescription: [''],
      permission: [''],
      clientVisible: [''],
      customParticipants: [''],
      taskPriority: ['', Validators.required]
    });
  }

  isTimeRight(event){
    let check = event.target.value;
    this.taskHourFlag = check ? this.checkEstimationHour(check) : false;
  }

  checkEstimationHour(timeData) {
    timeData =  timeData.split('.') ;
    if (timeData.length > 1 && timeData[1] >= 1) {
      let minutes = timeData[1];
      let hours = timeData[0];
      if(minutes.length === 2){
        if(minutes< 60) {
          return false;
        } else {
          this.toastr.error('Please enter less than 60 minutes');
          return true;
        }
      } else {
        if (minutes.length ===1 && hours < 100) {
          minutes = '0'+minutes;
          hours = hours >=1 ? hours : '0';
          this.toastr.error('Please enter minutes in double digits \n Ex : ' +hours+'.'+minutes  + ' => '+hours+' Hours '+minutes+' Minutes');
          return true;
        } else {
          this.toastr.error('Please enter minutes in double digits \n Ex : 2.05 => 2 Hours 05 Minutes');
          return true;
        }
      }
    } else {
      return false;
    }
  }

  viewDetailPage(taskId, type) {
    const dialogConfig = Common.matDialogConfig;
    dialogConfig.data = { taskId: taskId, type: type };
    const dialogRef = this.dialog.open(TaskDetailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.action == "edit") {
          result.taskId;
          this.taskService.updateTasksEventListener.next(result.taskId.toString());
          this.tabGroup.selectedIndex = 1;
        } else {
          this.tabGroup.selectedIndex = 0;
        }
      }
    });
  }

  filterChangeDept(event) {
    this.selectDeptIdUpdate = event === undefined;
    this.deptIdCheck = event.value;
  }
  filterChangeStatus(event) {
    this.selectTaskStatusUpdate = event === undefined;
  }

  saveTask() {
    if (this.taskHourFlag) {
      return;
    }
    this.submitted = true;
    this.selectTaskStatusUpdate = this.createTaskForm.value.taskStatus === null;
    this.selectDeptIdUpdate = this.createTaskForm.value.deptId === null;

    this.selectPriority = this.createTaskForm.value.taskPriority === null;

    this.selectUserIdUpdate = this.createTaskForm.value.responsibleUserId == null;
    this.createTaskForm.value.customParticipants = this.createTaskForm.value.customParticipants ? this.createTaskForm.value.customParticipants : '';
    if (Number(this.createTaskForm.value.permission) === 3 && this.createTaskForm.value.customParticipants.length === 0) {
      this.salesrepCheck = true;
      return;
    } else if (Number(this.createTaskForm.value.permission) === 3 && this.createTaskForm.value.customParticipants) {
      this.salesrepCheck = false;
      var splitVal = this.createTaskForm.value.customParticipants;
      this.participantsList = splitVal.join(',');
    } else {
      this.participantsList = '';
    }
    if (this.createTaskForm.value.permission == null || this.createTaskForm.value.permission == '') {
      this.radioValue = 1;
    } else {
      this.radioValue = this.createTaskForm.value.permission;
    }
    if (this.createTaskForm.invalid) {
      // navigate to invalid control
      this.navigateToInvalidControl(this.createTaskForm.controls, 'Task');
      return;
    } else {
      const startDate = moment(this.createTaskForm.value.taskStartDate).format('YYYY-MM-DD');
      const dueDate = (moment(this.createTaskForm.value.dueDate).format('YYYY-MM-DD'));
      const startTime = moment(this.createTaskForm.value.taskStartDate).format('HH:mm:ss');
      const dueTime = moment(this.createTaskForm.value.dueDate).format('HH:mm:ss');

      if (new Date(moment(this.createTaskForm.value.taskStartDate).format('YYYY-MM-DD')) > new Date(moment(this.createTaskForm.value.dueDate).format('YYYY-MM-DD'))) {
        this.toastr.error(ToastMessages.INVALID_DATE);
        return;
      }else if(startDate === 'Invalid date' || startTime === 'Invalid date' || dueDate === 'Invalid date' || dueTime === 'Invalid date'){
        this.toastr.error(ToastMessages.INVALID_DATE_FORMAT);
        return;
      }
      else if( startDate === dueDate){
        if(startTime > dueTime ){
          this.toastr.error(ToastMessages.INVALID_TIME);
          return;
        }
      }

      var requiredDataArray = this.customFieldValidation(this.customfieldsArray, this.customFieldData);
      for (let i = 0; i < requiredDataArray.length; i++) {
        if (requiredDataArray[i]) {
          this.projectService.projectDetail.customFieldRequiredData = requiredDataArray;
          return;
        }
      }
      if (this.viewType === 'tickets' && this.lockStatus === 1) {
        this.toastr.error('Ticket has been locked and no changes can be made. Please unlock the ticket first');
      } else {
        let assignedUser;
        if (this.radioValue === 1) {
          assignedUser = this.configService.userId;
        } else {
          assignedUser = this.createTaskForm.value.customParticipants;
        }
        var stDate = moment(this.createTaskForm.value.taskStartDate).format('YYYY-MM-DD');
        var stTime = moment(this.createTaskForm.value.taskStartDate).format('HH:mm:ss');
        if (Number(this.createTaskForm.value.permission) === 3 && this.createTaskForm.value.customParticipants === '') {
          this.salesrepCheck = true;
        } else if (Number(this.createTaskForm.value.permission) === 3 && this.createTaskForm.value.customParticipants !== '') {
          this.salesrepCheck = false;
          var splitVal = this.createTaskForm.value.customParticipants;
          this.participantsList = splitVal.join(',');
        } else {
          this.participantsList = '';
        }
        if (this.selected === undefined || this.selected === null) {
          this.taskData = {
            projectId: Number(this.createTaskForm.value.projectId) ? Number(this.createTaskForm.value.projectId) : 0,
            milestonesId: Number(this.createTaskForm.value.milestonesId) ? Number(this.createTaskForm.value.milestonesId) : 0,
            opportunitiesId: Number(this.createTaskForm.value.opportunitiesId) ? Number(this.createTaskForm.value.opportunitiesId) : 0,
            goalTrackingId: Number(this.createTaskForm.value.goalTrackingId) ? Number(this.createTaskForm.value.goalTrackingId) : 0,
            ticketsId: Number(this.createTaskForm.value.ticketsId) ? Number(this.createTaskForm.value.ticketsId) : 0,
            deptId: Number(this.createTaskForm.value.deptId) ? Number(this.createTaskForm.value.deptId) : null,
            leadsId: Number(this.createTaskForm.value.leadsId) ? Number(this.createTaskForm.value.leadsId) : 0,
            clientId: Number(this.createTaskForm.value.clientId) ? Number(this.createTaskForm.value.clientId) : 0,
            bugId: Number(this.createTaskForm.value.bugId) ? Number(this.createTaskForm.value.bugId) : 0,
            responsibleUserId: Number(this.createTaskForm.value.responsibleUserId) ? Number(this.createTaskForm.value.responsibleUserId) : null,
            taskName: String(this.createTaskForm.value.taskName).trim(),
            taskDescription: this.createTaskForm.value.taskDescription? String(this.createTaskForm.value.taskDescription): undefined,
            taskStartDate: String(stDate),
            taskStartTime: String(stTime),
            dueDate: moment(this.createTaskForm.value.dueDate).format('YYYY-MM-DD'),
            dueTime: moment(this.createTaskForm.value.dueDate).format('HH:mm:ss'),
            taskStatus: String(this.createTaskForm.value.taskStatus),
            taskProgress: Number(this.createTaskForm.value.taskProgress) ? Number(this.createTaskForm.value.taskProgress) : 0,
            taskHour: Number(this.createTaskForm.value.taskHour) ? Number(this.createTaskForm.value.taskHour) : 0,
            permission: Number(this.radioValue) ? Number(this.radioValue) : null,
            clientVisible: String(this.createTaskForm.value.clientVisible),
            assignedTo: String(assignedUser),
            customFields: this.customFieldData ? this.customFieldData : null,
            taskPriorities: this.createTaskForm.value.taskPriority ? this.createTaskForm.value.taskPriority : null
          };
          this.taskService.createTask(this.taskData).subscribe((result: any) => {
              if (result.info.code === EStatusCode.OK || result.info.code === EStatusCode.CREATED) {
                this.taskService.viewType = this.viewType ? this.viewType : null;
                this.taskService.moduleId = (this.viewType === 'project') ? this.projectId : this.moduleId;
                this.dashboardService.headerNotificationEventListener.next(true);
                if (this.viewType === 'client' || this.viewType === 'bugs') {
                  this.calculateCount.next();
                }
                if (this.viewType === 'opportunities') {
                  this.calculateLeadCount.next();
                  this.detailCount.next();
                  this.opportunitiesService.selectedIndexType = 'tasks';
                  this.routerDialog.navigateByUrl('admin/tasks/details/' + result.data.taskId);
                  this.opportunitiesService.oppoDetail.oppoDialog.close()
                } else if (this.viewType === 'project') {
                  this.projectDetailCount.next();
                  this.projectService.selectedIndexType = 'tasks';
                  this.projectService.projectDetail.projectDialog.close();
                  this.routerDialog.navigateByUrl('admin/tasks/details/' + result.data.taskId);
                } else if (this.viewType === 'tickets') {
                  this.ticketDetailCount.next();
                  this.ticketsService.selectedIndexType = 'tasks';
                  this.ticketsService.ticketDetail.ticketDialog.close();
                  this.routerDialog.navigateByUrl('admin/tasks/details/' + result.data.taskId);
                } else {
                  this.taskService.setTaskDetailType('');
                  if (this.viewType === 'client') {
                    this.clientService.selectedIndexType = 'tasks';
                    this.clientService.clientDetail.url = 'admin/tasks/details/' + result.data.taskId;
                    this.clientService.clientDetail.clientDialog.close();
                  } else if (this.viewType === 'bugs') {
                    this.bugsService.selectedIndexType = 'tasks';
                    this.bugsService.bugDetail.msg = 'bug-task';
                    this.bugsService.bugDetail.url = 'admin/tasks/details/' + result.data.taskId;
                    this.bugsService.bugDetail.bugDialog.close();
                  } else if (this.viewType === 'leads' || this.viewType === 'Leads') {
                    this.leadsService.selectedIndexType = 'tasks';
                    this.calculateLeadCount.next();
                    this.detailCount.next();
                    this.leadsService.leadsDetail.url = 'admin/tasks/details/' + result.data.taskId;
                    this.leadsService.leadsDetail.leadsDialog.close();
                  } else {
                    this.clientService.clientDetail.url = this.bugsService.bugDetail.url = this.leadsService.leadsDetail.url = this.opportunitiesService.oppoDetail.url = window.location.pathname;
                  }
                  this.routerDialog.navigateByUrl('admin/tasks/details/' + result.data.taskId);
                }
                this.tabGroup.selectedIndex = 0;
              } else {
                this.toastr.error('Task Not Created');
              }
            },
            error => {
              this.toastr.error('Could not take action because of missing data, please check the form and try again');
            });
        } else {
          this.taskData = {
            projectId: (this.selected === 'project') ? Number(this.createTaskForm.value.projectId) : 0,
            milestonesId: (this.selected === 'project') ? Number(this.createTaskForm.value.milestonesId) : 0,
            opportunitiesId: (this.selected === 'opp') ? Number(this.createTaskForm.value.opportunitiesId) : 0,
            goalTrackingId: (this.selected === 'goal') ? Number(this.createTaskForm.value.goalTrackingId) : 0,
            ticketsId: (this.selected === 'ticket') ? Number(this.createTaskForm.value.ticketsId) : 0,
            deptId: Number(this.createTaskForm.value.deptId) ? Number(this.createTaskForm.value.deptId) : null,
            leadsId: (this.selected === 'leads') ? Number(this.createTaskForm.value.leadsId) : 0,
            clientId: (this.selected === 'client') ? Number(this.createTaskForm.value.clientId) : 0,
            bugId: (this.selected === 'bugs') ? Number(this.createTaskForm.value.bugId) : 0,
            responsibleUserId: Number(this.createTaskForm.value.responsibleUserId) ? Number(this.createTaskForm.value.responsibleUserId) : null,
            taskName: String(this.createTaskForm.value.taskName).trim(),
            taskDescription: this.createTaskForm.value.taskDescription?  String(this.createTaskForm.value.taskDescription) :'',
            taskStartDate: String(stDate),
            taskStartTime: String(stTime),
            dueDate: moment(this.createTaskForm.value.dueDate).format('YYYY-MM-DD'),
            dueTime: moment(this.createTaskForm.value.dueDate).format('HH:mm:ss'),
            taskStatus: String(this.createTaskForm.value.taskStatus),
            taskProgress: Number(this.createTaskForm.value.taskProgress) ? Number(this.createTaskForm.value.taskProgress) : 0,
            taskHour: Number(this.createTaskForm.value.taskHour) ? Number(this.createTaskForm.value.taskHour) : 0,
            permission: Number(this.radioValue) ? Number(this.radioValue) : null,
            clientVisible: String(this.createTaskForm.value.clientVisible),
            assignedTo: String(assignedUser),
            customFields: this.customFieldData ? this.customFieldData : null,
            taskPriorities: this.createTaskForm.value.taskPriority ? this.createTaskForm.value.taskPriority : null
          };
          this.taskService.createTask(this.taskData).subscribe((result: any) => {
              if (result.info.code === EStatusCode.OK || result.info.code === EStatusCode.CREATED) {
                this.taskService.viewType = this.viewType ? this.viewType : null;
                this.taskService.moduleId = (this.viewType === 'project') ? this.projectId : this.moduleId;
                this.dashboardService.headerNotificationEventListener.next(true);
                if (this.viewType === 'client' || this.viewType === 'bugs') {
                  this.calculateCount.next();
                }
                if (this.viewType === 'opportunities') {
                  this.opportunitiesService.selectedIndexType = 'tasks';
                  this.calculateLeadCount.next();
                  this.routerDialog.navigateByUrl('admin/tasks/details/' + result.data.taskId);
                  this.opportunitiesService.oppoDetail.oppoDialog.close();
                } else if (this.viewType === 'project') {
                  this.projectService.selectedIndexType = 'tasks';
                  this.projectDetailCount.next();
                  this.routerDialog.navigateByUrl('admin/tasks/details/' + result.data.taskId);
                  this.projectService.projectDetail.projectDialog.close();
                } else if (this.viewType === 'tickets') {
                  this.ticketsService.selectedIndexType = 'tasks';
                  this.ticketDetailCount.next();
                  this.ticketsService.ticketDetail.ticketDialog.close();
                  this.routerDialog.navigateByUrl('admin/tasks/details/' + result.data.taskId);
                } else {
                  this.taskService.setTaskDetailType('');
                  if (this.viewType === 'client') {
                    this.clientService.selectedIndexType = 'tasks';
                    this.clientService.clientDetail.url = 'admin/tasks/details/' + result.data.taskId;
                    this.clientService.clientDetail.clientDialog.close();
                  } else if (this.viewType === 'bugs') {
                    this.bugsService.selectedIndexType = 'tasks';
                    this.bugsService.bugDetail.msg = 'bug-task';
                    this.bugsService.bugDetail.url = 'admin/tasks/details/' + result.data.taskId;
                    this.bugsService.bugDetail.bugDialog.close();
                  } else if (this.viewType === 'leads' || this.viewType === 'Leads') {
                    this.leadsService.selectedIndexType = 'tasks';
                    this.calculateLeadCount.next();
                    this.leadsService.leadsDetail.url = 'admin/tasks/details/' + result.data.taskId;
                    this.leadsService.leadsDetail.leadsDialog.close();
                  } else {
                    this.clientService.clientDetail.url = this.bugsService.bugDetail.url = this.leadsService.leadsDetail.url = this.opportunitiesService.oppoDetail.url = window.location.pathname;
                  }
                  this.routerDialog.navigateByUrl('admin/tasks/details/' + result.data.taskId);
                }
                this.tabGroup.selectedIndex = 0;
              } else {
                this.toastr.error('Task Not Created');
              }
            },
            error => {
              this.toastr.error('Could not take action because of missing data, please check the form and try again');
            });
        }

      }
    }
  }

  getTaskDetails(taskId) {
    let task, customRoleId = [];
    let assignmentType;
    let val = [];
    this.isEdit = true;

    let loginUserId = this.configService.userId;
    const detailByIDS : Subscription = this.taskService.detailsById(taskId).subscribe((details: any) => {
      if (details.info.code === EStatusCode.OK) {
        task = details['data'][0];
        this.customFieldValue = task.customFieldUpdate;
        this.showCustomFields = true;
        this.getMilestoneList(task.projectId);
        this.customFieldValue = this.getDefaultValueCustomField(this.customfieldsArray, this.customFieldValue);
        if (task.users !== null) {
          val = task.users;
          this.getInActiveUserList(val);
          if (val.length > 0) {
            for (let i = 0; i < val.length; i++) {
              customRoleId.push(val[i].userId);
            }
          }
        }
        let myselfFlag = 0;

        if (customRoleId.length === 1 && loginUserId === customRoleId[0]) {

          myselfFlag = 1;
        }

        if (task.permissionType === 3) {

          if (myselfFlag) {

            assignmentType = 1;
            this.radioValue = 1;
            customRoleId = [];
          } else {
            assignmentType = 3;
            this.radioValue = 3;
            this.showParticipants = true;
            setTimeout(() => {
              this.showParticipants = true;
            }, 500);
          }


        } else if (task.permissionType === 1) {
          if (myselfFlag) {
            assignmentType = 1;
            this.radioValue = 1;
            customRoleId = [];
          } else {
            assignmentType = 3;
            this.radioValue = 3;
            this.showParticipants = true;
            setTimeout(() => {
              this.showParticipants = true;
            }, 500);
          }

        } else if (task.permissionType === 2) {
          assignmentType = 2;
          this.radioValue = 2;
          this.createTaskForm.controls['customParticipants'].reset();
          customRoleId = [];

        }
        this.deptIdCheck = task.deptId;
        if(this.departmentIds.includes(task.deptId)){
            this.createTaskForm.patchValue({
              deptId: task.deptId,
            })
        }
        else{
          this.createTaskForm.controls['deptId'].reset();
        }
        this.datelimit = task.taskStartDate;
        this.participantsArr = customRoleId;
        this.createTaskForm.patchValue({
          taskName: task.taskName,
          projectId: task.projectId,
          milestonesId: task.milestonesId,
          opportunitiesId: task.opportunitiesId,
          bugId: task.bugId,
          leadsId: task.leadsId,
          clientId: task.clientId,
          ticketsId: task.ticketsId,
          goalTrackingId: task.goalTrackingId,
          responsibleUserId: task.responsibleUserId,
          taskDescription: task.taskDescription,
          taskStartDate: task.taskStartDate,
          dueDate: task.dueDate,
          taskStatus: task.taskStatus,
          taskProgress: task.taskProgress,
          taskPriority: task.taskPriority,
          taskHour: task.taskHour,
          permission: assignmentType,
          customParticipants: this.dropDownUsers.length ? customRoleId : [],
        });
        if (task.goalTrackingId != null) {
          this.selected = 'goal';
          this.sel = 'Goal Tracking';
          this.createTaskForm.patchValue({relatedtomodule: 'goal'});
        } else if (task.projectId != null) {
          this.selected = 'project';
          this.sel = 'Project';
          this.createTaskForm.patchValue({relatedtomodule: 'project'});
          // Get milestone for selected project
          this.getMilestoneList(task.projectId);

        } else if (task.opportunitiesId != null) {
          this.selected = 'opp';
          this.sel = 'Opportunities';
          this.createTaskForm.patchValue({relatedtomodule: 'opp'});
        } else if (task.bugId != null) {
          this.selected = 'bugs';
          this.sel = 'Bugs';
          this.createTaskForm.patchValue({relatedtomodule: 'bugs'});
        } else if (task.leadsId != null) {
          this.selected = 'leads';
          this.sel = 'Leads';
          this.viewType = 'Leads';
          this.createTaskForm.patchValue({relatedtomodule: 'leads'});
        } else if (task.ticketsId != null) {
          this.selected = 'ticket';
          this.sel = 'Tickets';
          this.createTaskForm.patchValue({relatedtomodule: 'ticket'});
        } else if (task.clientId != null) {
          this.selected = 'client';
          this.sel = 'CLient';
          this.createTaskForm.patchValue({relatedtomodule: 'client'});
        }
      }
    });
    this.subscriptions.push(detailByIDS);
  }

  get f() {
    return this.createTaskForm.controls;
  }

  /**
   * @description Updates Task
   */
  updateTask() {
    if (this.taskHourFlag) {
      return;
    }
    this.submitted = true;
    this.selectTaskStatusUpdate = this.createTaskForm.value.taskStatus == null;
    this.selectDeptIdUpdate = this.createTaskForm.value.deptId == null;
    this.selectPriority = this.createTaskForm.value.taskPriority == null;
    this.selectUserIdUpdate = this.createTaskForm.value.responsibleUserId == null;
    if (Number(this.createTaskForm.value.permission) === 3 && this.createTaskForm.value.customParticipants.length === 0) {
      this.salesrepCheck = true;
      return;
    } else if (Number(this.createTaskForm.value.permission) === 3 && this.createTaskForm.value.customParticipants) {
      this.salesrepCheck = false;
      var splitVal = this.createTaskForm.value.customParticipants;
      this.participantsList = splitVal.join(',');
    } else if (this.createTaskForm.value.permission === 1) {
      this.participantsList = String(this.configService.userId);
    } else {
      this.participantsList = '';
    }
    if (this.createTaskForm.value.permission == null) {
      this.radioValue = 1;
    }
    else {
      this.radioValue = this.createTaskForm.value.permission;
    }
    if(!this.departmentIds.includes(this.deptIdCheck)){
      this.toastr.error("You have unsubscribed to the module, please subscribe and update the task or select any other department!");
    }
    else{
      if (this.createTaskForm.invalid) {
        // navigate to invalid control
        this.navigateToInvalidControl(this.createTaskForm.controls, 'Task');
        return;
      } else {
        const stDate=moment(this.createTaskForm.value.taskStartDate).format('YYYY-MM-DD');
        const dueDate =(moment(this.createTaskForm.value.dueDate).format('YYYY-MM-DD'));
        const stTime = moment(this.createTaskForm.value.taskStartDate).format('HH:mm:ss');
        const dueTime = moment(this.createTaskForm.value.dueDate).format('HH:mm:ss');
        if (new Date( moment(this.createTaskForm.value.taskStartDate).format('YYYY-MM-DD')) > new Date( moment(this.createTaskForm.value.dueDate).format('YYYY-MM-DD'))) {
          this.toastr.error(ToastMessages.INVALID_DATE);
          return;
        }else if(stDate === 'Invalid date' || stTime === 'Invalid date' || dueDate === 'Invalid date' || dueTime === 'Invalid date'){
          this.toastr.error(ToastMessages.INVALID_DATE_FORMAT);
          return;
        }
        else if( stDate === dueDate){
          if(stTime > dueTime ){
            this.toastr.error(ToastMessages.INVALID_TIME);
            return;
          }
        }

        var requiredDataArray = this.customFieldValidation(this.customfieldsArray, this.customFieldData);
        for (let i = 0; i < requiredDataArray.length; i++) {
          if (requiredDataArray[i]) {
            this.projectService.projectDetail.customFieldRequiredData = requiredDataArray;
            return;
          }
        }
        if (this.viewType === 'tickets' && this.lockStatus === 1) {
          this.toastr.error('Ticket has been locked and no changes can be made. Please unlock the ticket first');
        } else {

            if (this.selected === undefined || this.selected === null) {
              this.taskData = {
                taskId: this.editTaskId,
                projectId: Number(this.createTaskForm.value.projectId) ? Number(this.createTaskForm.value.projectId) : 0,
                milestonesId: Number(this.createTaskForm.value.milestonesId) ? Number(this.createTaskForm.value.milestonesId) : 0,
                opportunitiesId: Number(this.createTaskForm.value.opportunitiesId) ? Number(this.createTaskForm.value.opportunitiesId) : 0,
                goalTrackingId: Number(this.createTaskForm.value.goalTrackingId) ? Number(this.createTaskForm.value.goalTrackingId) : 0,
                ticketsId: Number(this.createTaskForm.value.ticketsId) ? Number(this.createTaskForm.value.ticketsId) : 0,
                deptId: Number(this.createTaskForm.value.deptId) ? Number(this.createTaskForm.value.deptId) : null,
                leadsId: Number(this.createTaskForm.value.leadsId) ? Number(this.createTaskForm.value.leadsId) : 0,
                clientId: Number(this.createTaskForm.value.clientId) ? Number(this.createTaskForm.value.clientId) : 0,
                bugId: Number(this.createTaskForm.value.bugId) ? Number(this.createTaskForm.value.bugId) : 0,
                responsibleUserId: Number(this.createTaskForm.value.responsibleUserId) ? Number(this.createTaskForm.value.responsibleUserId) : null,
                taskName: String(this.createTaskForm.value.taskName).trim(),
                taskDescription: this.createTaskForm.value.taskDescription? String(this.createTaskForm.value.taskDescription): undefined,
                taskStartDate: moment(this.createTaskForm.value.taskStartDate).format('YYYY-MM-DD'),
                taskStartTime: moment(this.createTaskForm.value.taskStartDate).format("HH:mm:ss"),
                dueDate: moment(this.createTaskForm.value.dueDate).format('YYYY-MM-DD'),
                taskDueTime: moment(this.createTaskForm.value.dueDate).format("HH:mm:ss"),
                taskStatus: String(this.createTaskForm.value.taskStatus),
                taskProgress: Number(this.createTaskForm.value.taskProgress) ? Number(this.createTaskForm.value.taskProgress) : 0,
                taskHour: Number(this.createTaskForm.value.taskHour) ? Number(this.createTaskForm.value.taskHour) : 0,
                permission: Number(this.createTaskForm.value.permission) ? Number(this.createTaskForm.value.permission) : 0,
                clientVisible: String(this.createTaskForm.value.clientVisible),
                assignedTo: String(this.participantsList),
                customFields: this.customFieldData ? this.customFieldData : null,
                taskPriorities: this.createTaskForm.value.taskPriority ? this.createTaskForm.value.taskPriority : null
              };

              this.taskService.modify(this.editTaskId, this.taskData).subscribe((result: any) => {
                  if (result.info.code === EStatusCode.OK || result.info.code === EStatusCode.CREATED) {
                    this.taskService.viewType = this.viewType ? this.viewType : null;
                    this.taskService.moduleId = (this.viewType === 'project') ? this.projectId : this.moduleId;
                    this.toastr.success(result.data.responseMsg);
                    this.dashboardService.headerNotificationEventListener.next(true);
                    if (result.data.count == 1) {
                      this.calculateLeadCount.next();
                      this.tabGroup.selectedIndex = 0;
                      this.taskService.setTaskDetailType('');
                      if (this.viewType === 'client') {
                        this.clientService.selectedIndexType = 'tasks';
                        this.clientService.clientDetail.url = 'admin/tasks/details/' + this.editTaskId;
                        this.clientService.clientDetail.clientDialog.close();
                      } else if (this.viewType === 'bugs') {
                        this.bugsService.selectedIndexType = 'tasks';
                        this.bugsService.bugDetail.msg = 'bug-task';
                        this.bugsService.bugDetail.url = 'admin/tasks/details/' + this.editTaskId;
                        this.bugsService.bugDetail.bugDialog.close();
                      } else if (this.viewType === 'leads') {
                        this.leadsService.selectedIndexType = 'tasks';
                        this.leadsService.leadsDetail.url = 'admin/tasks/details/' + this.editTaskId;
                        this.leadsService.leadsDetail.leadsDialog.close();
                      } else if (this.viewType === 'opportunities') {
                        this.opportunitiesService.selectedIndexType = 'tasks';
                        this.opportunitiesService.oppoDetail.url = 'admin/tasks/details/' + this.editTaskId;
                        this.opportunitiesService.oppoDetail.oppoDialog.close();
                      } else {
                        this.clientService.clientDetail.url = this.bugsService.bugDetail.url = this.leadsService.leadsDetail.url = this.opportunitiesService.oppoDetail.url = window.location.pathname;
                      }
                      this.routerDialog.navigateByUrl('admin/tasks/details/' + this.editTaskId);
                      this.calculateCount.next();

                      // Stop timer & update floating timer
                      if(this.createTaskForm.value.taskStatus === 'completed') {
                        const data = {
                          'taskId': this.editTaskId,
                          'taskStatus': this.createTaskForm.value.taskStatus
                        };
                        this.taskService.setStatusUpdate(data).subscribe(res => {
                        });
                      }

                    } else {
                      this.tabGroup.selectedIndex = 0;
                    }
                  } else {
                    this.toastr.error("Task Updation Failed");
                  }
                },
                error => {
                  this.toastr.error("Could not take action because of missing data, please check the form and try again");
                });
            } else {

              this.taskData = {
                taskId: this.editTaskId,
                projectId: (this.selected === 'project') ? Number(this.createTaskForm.value.projectId) : 0,
                milestonesId: (this.selected === 'project') ? Number(this.createTaskForm.value.milestonesId) : 0,
                opportunitiesId:  (this.selected === 'opp') ? Number(this.createTaskForm.value.opportunitiesId) : 0,
                goalTrackingId: (this.selected === 'goal') ? Number(this.createTaskForm.value.goalTrackingId) : 0,
                ticketsId: (this.selected === 'ticket') ? Number(this.createTaskForm.value.ticketsId) : 0,
                deptId: Number(this.createTaskForm.value.deptId) ? Number(this.createTaskForm.value.deptId) : null,
                leadsId: (this.selected === 'leads') ? Number(this.createTaskForm.value.leadsId) : 0,
                clientId: (this.selected === 'client') ? Number(this.createTaskForm.value.clientId) : 0,
                bugId: (this.selected === 'bugs') ? Number(this.createTaskForm.value.bugId) : 0,
                responsibleUserId: Number(this.createTaskForm.value.responsibleUserId) ? Number(this.createTaskForm.value.responsibleUserId) : null,
                taskName: String(this.createTaskForm.value.taskName).trim(),
                taskDescription: this.createTaskForm.value.taskDescription? String(this.createTaskForm.value.taskDescription): undefined,
                taskStartDate: moment(this.createTaskForm.value.taskStartDate).format('YYYY/MM/DD'),
                taskStartTime: moment(this.createTaskForm.value.taskStartDate).format("HH:mm:ss"),
                dueDate: moment(this.createTaskForm.value.dueDate).format('YYYY/MM/DD'),
                taskDueTime: moment(this.createTaskForm.value.dueDate).format("HH:mm:ss"),
                taskStatus: String(this.createTaskForm.value.taskStatus),
                taskProgress: Number(this.createTaskForm.value.taskProgress) ? Number(this.createTaskForm.value.taskProgress) : 0,
                taskHour: Number(this.createTaskForm.value.taskHour) ? Number(this.createTaskForm.value.taskHour) : 0,
                permission: Number(this.createTaskForm.value.permission) ? Number(this.createTaskForm.value.permission) : 0,
                clientVisible: String(this.createTaskForm.value.clientVisible),
                assignedTo: String(this.participantsList),
                customFields: this.customFieldData ? this.customFieldData : null,
                taskPriorities: this.createTaskForm.value.taskPriority ? this.createTaskForm.value.taskPriority : null};
              this.taskService.modify(this.editTaskId, this.taskData).subscribe((result: any) => {
                  if (result.info.code === EStatusCode.OK || result.info.code === EStatusCode.CREATED) {
                    this.taskService.viewType = this.viewType ? this.viewType : null;
                    this.taskService.moduleId = (this.viewType === 'project') ? this.projectId : this.moduleId;
                    this.toastr.success(result.data.responseMsg);
                    this.dashboardService.headerNotificationEventListener.next(true);
                    if (result.data.count == 1) {
                      this.calculateLeadCount.next();
                      this.tabGroup.selectedIndex = 0;
                      this.taskService.setTaskDetailType('');
                      console.log(this.viewType)
                      if (this.viewType === 'client') {
                        this.clientService.selectedIndexType = 'tasks';
                        this.clientService.clientDetail.url = 'admin/tasks/details/' + this.editTaskId;
                        this.clientService.clientDetail.clientDialog.close();
                      } else if (this.viewType === 'bugs') {
                        this.bugsService.selectedIndexType = 'tasks';
                        this.bugsService.bugDetail.msg = 'bug-task';
                        this.bugsService.bugDetail.url = 'admin/tasks/details/' + this.editTaskId;
                        this.bugsService.bugDetail.bugDialog.close();
                      } else if (this.viewType === 'project') {
                        this.projectService.selectedIndexType = 'tasks';
                        this.projectService.projectDetail.projectDialog.close();
                      } else if (this.viewType === 'tickets') {
                        this.ticketsService.selectedIndexType = 'tasks';
                        this.ticketsService.ticketDetail.ticketDialog.close();
                      } else if (this.viewType === 'leads' || this.viewType === 'Leads') {
                        this.leadsService.selectedIndexType = 'tasks';
                        this.leadsService.leadsDetail.url =  'admin/tasks/details/' + this.editTaskId;
                        this.leadsService.leadsDetail.leadsDialog.close();
                      } else if (this.viewType === 'opportunities') {
                        this.opportunitiesService.selectedIndexType = 'tasks';
                        this.opportunitiesService.oppoDetail.url = 'admin/tasks/details/' + this.editTaskId;
                        this.opportunitiesService.oppoDetail.oppoDialog.close();
                      } else {
                        this.clientService.clientDetail.url = this.bugsService.bugDetail.url = this.leadsService.leadsDetail.url = this.opportunitiesService.oppoDetail.url = window.location.pathname;
                      }
                      this.routerDialog.navigateByUrl('admin/tasks/details/' + this.editTaskId);
                      this.calculateCount.next();

                      // Stop timer & update floating timer
                      if(this.createTaskForm.value.taskStatus === 'completed') {
                        const data = {
                          'taskId': this.editTaskId,
                          'taskStatus': this.createTaskForm.value.taskStatus
                        };
                        this.taskService.setStatusUpdate(data).subscribe(res => {
                        });
                      }

                    } else {
                      this.tabGroup.selectedIndex = 0;
                    }
                  } else {
                    this.toastr.error("Task Updation Failed");
                  }
                },
                error => {
                  this.toastr.error("Could not take action because of missing data, please check the form and try again");
                });
            }
          }
        }
      }

  }

  datechange(event) {
    this.datelimit = event.value;
    var startDate = moment(this.createTaskForm.value.taskStartDate).format('YYYY/MM/DD');
    var dueDate = moment(this.createTaskForm.value.dueDate).format('YYYY/MM/DD');
    if (startDate > dueDate) {
      this.createTaskForm.patchValue({
        dueDate: event.value,
      });
    }
  }

  getCustomFields() {
    const getCustomField : Subscription = this.settingService.getCustomFieldsList('', 'tasks', '').subscribe((res: IResponse) => {
      if (res.info.code === EStatusCode.OK && res.data.list) {
        this.customfieldsArray = res.data.list;
        this.isCustomFields = true;
      } else {
        this.customfieldsArray = [];
        this.isCustomFields = false;
      }
    });
    this.subscriptions.push(getCustomField);
  }
  //get custom field values
  getValuesOfCustomField(event) {
    if (event) {
      this.customFieldData = event;
    }
  }


  getSelectUser($event: any) {
    this.selectUserIdUpdate = !$event;
  }
}
