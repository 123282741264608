<div *ngIf="taskDetails" class="container">
    <div class="row">
        <div *ngIf="true" class="container-header">
            <span class="header-text">{{ taskDetails['taskName'] }}</span>
            <span *ngIf="clockTimerStatus == 'off' && taskDetails['taskListStatus'] !== 'COMPLETED' && isEditPermission">
                <button
                    class="btn btn-mini greenButton pull-right"
                    [disabled]="
                        taskDetails.inActiveStatus === 1 ||
                        taskDetails.clientIsDeleted === 1 ||
                        taskDetails.isStartTimeDisabled ||
                        activated === 0
                    "
                    (click)="
                        startTimer(taskDetails);
                        taskDetails.isStartTimeDisabled = true;
                        taskDetails.isStopTimeDisabled = false
                    ">
                    Start Timer
                </button>
            </span>

            <span *ngIf="clockTimerStatus == 'on' && taskDetails['taskListStatus'] !== 'COMPLETED' && isEditPermission" class="pull-right">
                <h4 class="timer_class">{{ timerDisplay | commonDate: 'timer' }}</h4>
                <button
                    class="btn btn-mini redButton pull-right"
                    [disabled]="
                        taskDetails.inActiveStatus === 1 ||
                        taskDetails.clientIsDeleted === 1 ||
                        taskDetails.isStopTimeDisabled ||
                        activated === 0
                    "
                    (click)="
                        stopTimer(timerClockId, taskstime, taskDetails['ticketLockStatus']);
                        taskDetails.isStartTimeDisabled = false;
                        taskDetails.isStopTimeDisabled = true
                    ">
                    Stop Timer
                </button>
            </span>
        </div>

        <div *ngIf="false" class="container-header">
            <span class="header-text">{{ taskDetails['taskName'] }}</span>
            <span *ngIf="clockTimerStatus == 'off' && taskDetails['taskListStatus'] !== 'COMPLETED' && isEditPermission">
                <button
                    class="btn btn-mini greenButton pull-right"
                    [disabled]="taskDetails.inActiveStatus === 1 || taskDetails.isStartTimeDisabled"
                    (click)="
                        startTimer(taskDetails);
                        taskDetails.isStartTimeDisabled = true;
                        taskDetails.isStopTimeDisabled = false
                    ">
                    Start Timer
                </button>
            </span>

            <span *ngIf="clockTimerStatus == 'on' && taskDetails['taskListStatus'] !== 'COMPLETED' && isEditPermission" class="pull-right">
                <h4 class="timer_class">{{ timerDisplay | commonDate: 'timer' }}</h4>
                <button
                    class="btn btn-mini redButton pull-right"
                    [disabled]="taskDetails.inActiveStatus === 1 || taskDetails.isStopTimeDisabled"
                    (click)="
                        stopTimer(timerClockId, taskstime, taskDetails['ticketLockStatus']);
                        taskDetails.isStartTimeDisabled = false;
                        taskDetails.isStopTimeDisabled = true
                    ">
                    Stop Timer
                </button>
            </span>
        </div>
    </div>
    <div class="row main-box">
        <div class="alert">
            <p class="m-0">
                <span class="due-text">
                    <i class="fa fa-calendar-alt"></i>
                    Due Date :
                </span>
                <span class="text-bold">
                    {{ taskDetails['dueDate'] | commonDate: 'date' }} {{ taskDetails['dueDate'] | commonDate: 'short_time_format' }}
                </span>
            </p>
        </div>
        <div class="row">
            <div class="col-md-8 task-text-light">
                <div *ngIf="showClientField">
                    <div class="row padding-y">
                        <div class="col-md-7">
                            <div *ngIf="!taskClientDetails && (taskDetails['ticketsId'] != null || taskDetails['projectId'] != null)">
                                <i class="fas fa-users"></i>
                                <span *ngIf="taskDetails?.relatedLocation">
                                    Location Name :
                                    <span class="text-bold section_lbl">{{ taskDetails?.relatedLocation }}</span>
                                </span>
                                <span *ngIf="!taskDetails?.relatedLocation">
                                    Client Name :
                                    <span class="text-bold section_lbl">{{ taskDetails?.relatedLocation }}</span>
                                </span>
                            </div>
                            <div *ngIf="!isClient">
                                <div *ngIf="taskDetails['leadsId'] != null">
                                    <i class="fas fa-rocket"></i>
                                    Lead Name :
                                    <span class="text-bold section_lbl">
                                        <a
                                            *ngIf="taskDetails.inActiveStatus !== 1"
                                            href="sales/leads/leadDetail/{{ taskDetails.leadsId }}"
                                            (click)="viewdetails(taskDetails['leadsId'], 'leads', taskDetails.inActiveStatus, $event)">
                                            {{ taskDetails['leadName'] }}
                                        </a>
                                        <span
                                            *ngIf="taskDetails.inActiveStatus === 1"
                                            class="client-invisible"
                                            (click)="viewdetails(taskDetails['leadsId'], 'leads', taskDetails.inActiveStatus, $event)">
                                            {{ taskDetails['leadName'] }}
                                        </span>
                                    </span>
                                </div>
                                <div *ngIf="taskDetails['clientId'] != null">
                                    <i class="fas fa-users"></i>
                                    Client Name :
                                    <span class="text-bold section_lbl">
                                        <a
                                            *ngIf="taskDetails.inActiveStatus !== 1"
                                            href="admin/client/details/{{ taskDetails.clientId }}"
                                            (click)="viewdetails(taskDetails['clientId'], 'client', taskDetails.inActiveStatus, $event)">
                                            {{ taskDetails['clientName'] }}
                                        </a>
                                        <span
                                            *ngIf="taskDetails.inActiveStatus === 1"
                                            class="client-invisible"
                                            (click)="viewdetails(taskDetails['clientId'], 'client', taskDetails.inActiveStatus, $event)">
                                            {{ taskDetails['clientName'] }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="isClient">
                                <div *ngIf="taskClientDetails['clientId']">
                                    <i class="fas fa-users"></i>
                                    Client Name :
                                    <span class="text-bold section_lbl">
                                        <a
                                            *ngIf="taskDetails.clientIsDeleted !== 1"
                                            href="admin/client/details/{{ taskClientDetails.clientId }}"
                                            (click)="
                                                viewdetails(taskClientDetails.clientId, 'client', taskDetails.clientIsDeleted, $event)
                                            ">
                                            {{ taskClientDetails.clientName }}
                                        </a>
                                        <span
                                            *ngIf="taskDetails.clientIsDeleted === 1"
                                            class="client-invisible"
                                            (click)="
                                                viewdetails(taskClientDetails.clientId, 'client', taskDetails.clientIsDeleted, $event)
                                            ">
                                            {{ taskClientDetails.clientName }}
                                        </span>
                                    </span>
                                </div>
                                <div *ngIf="taskClientDetails.leadsId">
                                    <i class="fas fa-rocket"></i>
                                    Lead Name :
                                    <span class="text-bold section_lbl">
                                        <a
                                            *ngIf="taskDetails.clientIsDeleted !== 1"
                                            href="sales/leads/leadDetail/{{ taskClientDetails.leadsId }}"
                                            (click)="viewdetails(taskClientDetails.leadsId, 'leads', taskDetails.clientIsDeleted, $event)">
                                            {{ taskClientDetails.leadName }}
                                        </a>
                                        <span
                                            *ngIf="taskDetails.clientIsDeleted === 1"
                                            class="client-invisible"
                                            (click)="viewdetails(taskClientDetails.leadsId, 'leads', taskDetails.clientIsDeleted, $event)">
                                            {{ taskClientDetails.leadName }}
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <!-- <div *ngIf ="isLocation">
                  <div><i class="fas fa-users"></i> Location Name : <span *ngIf="taskLocationDetails.locationName!=null" class="text-bold section_lbl">
                    <a (click)="viewdetails(taskLocationDetails.locationId,'location', taskDetails.clientIsDeleted, $event)" routerLink="/admin/locationandmarket/details/{{taskLocationDetails.locationId}}">{{taskLocationDetails.locationName}}</a></span>
                  </div>
                  <div *ngIf="taskLocationDetails.leadsId"><i class="fas fa-rocket"></i> Lead Name : <span class="text-bold section_lbl">
                    <a (click)="viewdetails(taskLocationDetails.leadsId,'leads',0, $event)" href="sales/leads/leadDetail/{{tasklocationDetails.leadsId}}">{{taskLocationDetails.leadName}}</a></span></div>
                  </div> -->
                        </div>
                        <div class="col-md-5">
                            <div *ngIf="taskDetails['projectId'] != null">
                                <i class="fas fa-project-diagram"></i>
                                Project Name :
                                <span class="text-bold section_lbl">
                                    <a
                                        *ngIf="taskDetails.inActiveStatus !== 1"
                                        href="support/project/details/{{ taskDetails.projectId }}"
                                        (click)="viewdetails(taskDetails['projectId'], 'project', taskDetails.inActiveStatus, $event)">
                                        {{ taskDetails['projectName'] }}
                                    </a>
                                    <span
                                        *ngIf="taskDetails.inActiveStatus === 1"
                                        class="client-invisible"
                                        (click)="viewdetails(taskDetails['projectId'], 'project', taskDetails.inActiveStatus, $event)">
                                        {{ taskDetails['projectName'] }}
                                    </span>
                                </span>
                            </div>
                            <div *ngIf="taskDetails['opportunitiesId'] != null">
                                <i class="fas fa-filter"></i>
                                Opportunities Name :
                                <span class="text-bold section_lbl">
                                    <a
                                        *ngIf="taskDetails.inActiveStatus !== 1"
                                        href="sales/opportunities/opportunityDetail/{{ taskDetails.opportunitiesId }}"
                                        (click)="
                                            viewdetails(taskDetails['opportunitiesId'], 'opportunity', taskDetails.inActiveStatus, $event)
                                        ">
                                        {{ taskDetails['opportunityName'] }}
                                    </a>
                                    <span
                                        *ngIf="taskDetails.inActiveStatus === 1"
                                        class="client-invisible"
                                        (click)="
                                            viewdetails(taskDetails['opportunitiesId'], 'opportunity', taskDetails.inActiveStatus, $event)
                                        ">
                                        {{ taskDetails['opportunityName'] }}
                                    </span>
                                </span>
                            </div>
                            <div *ngIf="taskDetails['bugId'] != null">
                                <i class="fas fa-bug"></i>
                                Bugs Name :
                                <span class="text-bold section_lbl">
                                    <a
                                        *ngIf="taskDetails.inActiveStatus !== 1"
                                        href="support/bugs/details/{{ taskDetails.bugId }}"
                                        (click)="viewdetails(taskDetails['bugId'], 'bugs', taskDetails.inActiveStatus, $event)">
                                        {{ taskDetails['bugTitle'] }}
                                    </a>
                                    <span
                                        *ngIf="taskDetails.inActiveStatus === 1"
                                        class="client-invisible"
                                        (click)="viewdetails(taskDetails['bugId'], 'bugs', taskDetails.inActiveStatus, $event)">
                                        {{ taskDetails['bugTitle'] }}
                                    </span>
                                </span>
                            </div>
                            <div *ngIf="taskDetails['goalTrackingId'] != null">
                                <i class="fab fa-galactic-republic"></i>
                                Goal Tracking :
                                <span class="text-bold section_lbl">{{ taskDetails['goalName'] }}</span>
                            </div>
                            <div *ngIf="taskDetails['ticketsId'] != null">
                                <i class="fas fa-ticket-alt"></i>
                                Tickets Code :
                                <span class="text-bold section_lbl">
                                    <a
                                        *ngIf="taskDetails.inActiveStatus !== 1"
                                        href="support/tickets/details/{{ taskDetails.ticketsId }}"
                                        (click)="viewdetails(taskDetails['ticketsId'], 'tickets', taskDetails.inActiveStatus, $event)">
                                        {{ taskDetails['ticketCode'] }}
                                    </a>
                                    <span
                                        *ngIf="taskDetails.inActiveStatus === 1"
                                        class="client-invisible"
                                        (click)="viewdetails(taskDetails['ticketsId'], 'tickets', taskDetails.inActiveStatus, $event)">
                                        {{ taskDetails['ticketCode'] }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                <div *ngIf="taskDetails['milestoneName'] != null">
                    <div class="row padding-y">
                        <div *ngIf="taskDetails['milestoneName'] != null" class="col-md-7">
                            <i class="fas fa-flag-checkered"></i>
                            Milestone
                            <span class="text-bold section_lbl">{{ taskDetails['milestoneName'] }}</span>
                        </div>
                    </div>
                    <hr />
                </div>
                <div class="row padding-y">
                    <div class="col-md-7">
                        <i class="far fa-edit"></i>
                        Created By :
                        <span class="text-bold section_lbl">{{ taskDetails['name'] }}</span>
                    </div>
                    <div class="col-md-5">
                        <i class="fas fa-cog" aria-hidden="true"></i>
                        Created On :
                        <span class="text-bold section_lbl">{{ taskDetails['taskCreatedDate'] | commonDate: 'date_time_timezone' }}</span>
                    </div>
                </div>

                <hr />
                <div class="row padding-y">
                    <div *ngIf="isMbm == '1'" class="col-md-7">
                        <div [ngClass]="!permissionService.tasks.edit ? 'noEditPermission' : ''">
                            <i class="far fa-check-square"></i>
                            Task Status :

                            <mat-menu #menu="matMenu">
                                <span
                                    *ngIf="
                                        this.permissionService.tasks.edit &&
                                        taskDetails.inActiveStatus !== 1 &&
                                        taskDetails.clientIsDeleted !== 1 &&
                                        activated === 1
                                    ">
                                    <button
                                        *ngFor="let ts of taskStatus"
                                        mat-menu-item
                                        (click)="statusUpdate(ts.value, taskDetails['taskId'], taskDetails.ticketLockStatus)">
                                        {{ ts.label }}
                                    </button>
                                </span>
                            </mat-menu>
                            <span *ngIf="taskDetails['taskListStatus'] === 'IN PROGRESS'">
                                <a class="statusbtn" [matMenuTriggerFor]="menu">
                                    <div disabled class="tag-btn progressButton">
                                        <span class="status_string">{{ 'task.task-list.inprogress' | translate }}</span>
                                        <span
                                            *ngIf="this.permissionService.tasks.edit && taskDetails.inActiveStatus !== 1 && activated === 1"
                                            class="caret"></span>
                                    </div>
                                </a>
                            </span>
                            <span *ngIf="taskDetails['taskListStatus'] === 'COMPLETED'">
                                <a class="statusbtn" [matMenuTriggerFor]="menu">
                                    <div disabled class="tag-btn successButton">
                                        <span class="status_string">{{ 'task.task-list.completed' | translate }}</span>
                                        <span
                                            *ngIf="
                                                this.permissionService.tasks.edit &&
                                                taskDetails.inActiveStatus !== 1 &&
                                                taskDetails.activated === 1
                                            "
                                            class="caret"></span>
                                    </div>
                                </a>
                            </span>
                            <span *ngIf="taskDetails['taskListStatus'] === 'STARTED'">
                                <a class="statusbtn" [matMenuTriggerFor]="menu">
                                    <div disabled class="tag-btn voiletButton">
                                        <span class="status_string">{{ 'task.task-list.started' | translate }}</span>
                                        <span
                                            *ngIf="this.permissionService.tasks.edit && taskDetails.inActiveStatus !== 1 && activated === 1"
                                            class="caret"></span>
                                    </div>
                                </a>
                            </span>
                            <span *ngIf="taskDetails['taskListStatus'] === 'CANCEL'">
                                <a class="statusbtn" [matMenuTriggerFor]="menu">
                                    <div disabled class="tag-btn dangerButton">
                                        <span class="status_string">{{ 'task.task-list.cancel' | translate }}</span>
                                        <span
                                            *ngIf="this.permissionService.tasks.edit && taskDetails.inActiveStatus !== 1 && activated === 1"
                                            class="caret"></span>
                                    </div>
                                </a>
                            </span>
                            <span *ngIf="taskDetails['taskListStatus'] === 'NOT STARTED'">
                                <a class="statusbtn" [matMenuTriggerFor]="menu">
                                    <div disabled class="tag-btn warningButton">
                                        <span class="status_string">{{ 'task.task-list.notstarted' | translate }}</span>
                                        <span
                                            *ngIf="this.permissionService.tasks.edit && taskDetails.inActiveStatus !== 1 && activated === 1"
                                            class="caret"></span>
                                    </div>
                                </a>
                            </span>
                            <span *ngIf="taskDetails['taskListStatus'] === 'WAITING FOR SOMEONE'">
                                <a class="statusbtn" [matMenuTriggerFor]="menu">
                                    <div disabled class="tag-btn btn_warning_to">
                                        <span class="status_string">{{ 'task.task-list.waiting' | translate }}</span>
                                        <span
                                            *ngIf="this.permissionService.tasks.edit && taskDetails.inActiveStatus !== 1 && activated === 1"
                                            class="caret"></span>
                                    </div>
                                </a>
                            </span>
                            <span *ngIf="taskDetails['taskListStatus'] === 'ON HOLD'">
                                <a class="statusbtn" [matMenuTriggerFor]="menu">
                                    <div disabled class="tag-btn btn_deferrd">
                                        <span class="status_string">{{ 'task.task-list.onhold' | translate }}</span>
                                        <span
                                            *ngIf="this.permissionService.tasks.edit && taskDetails.inActiveStatus !== 1 && activated === 1"
                                            class="caret"></span>
                                    </div>
                                </a>
                            </span>

                            <!-- <span class="tag-btn successButton" *ngIf="taskDetails['taskStatus'] == 'completed'">{{'task.task-list.completed'
              |
              translate }}</span>
            <span  class="tag-btn warningButton" *ngIf="taskDetails['taskStatus'] == 'not_started'"> {{'task.task-list.notstarted' | translate }}</span>
            <span class="tag-btn progressButton" *ngIf="taskDetails['taskStatus'] == 'in_progress'">{{'task.task-list.inprogress'
              | translate }}</span>
            <span class="tag-btn voiletButton" *ngIf="taskDetails['taskStatus'] == 'started'">{{'task.task-list.started'
              | translate }}</span>
            <span class="tag-btn dangerButton" *ngIf="taskDetails['taskStatus'] == 'cancel'">{{'task.task-list.cancel'
              | translate }}</span>
            <span class="tag-btn btn_deferrd" *ngIf="taskDetails['taskStatus'] == 'on_hold'"> On Hold </span>
            <span class="tag-btn btn_warning_to" *ngIf="taskDetails['taskStatus'] == 'waiting_for_someone'">Waiting for
              someone </span>
              <a class="btn btn-sm btn-action statusbtn" [matMenuTriggerFor]="menu" >
             <span class="btn btn-success">Change Status
               <span class="caret"></span></span>
           </a>
              <mat-menu #menu="matMenu" >
                    <button mat-menu-item *ngFor="let ts of taskStatus" (click)="statusUpdate(ts.value,taskDetails['taskId'],taskDetails.ticketLockStatus)">{{ts.label}}</button>
              </mat-menu> -->
                        </div>
                    </div>
                    <ng-container *ngIf="isMbm != '1'">
                        <div class="col-md-7" [ngClass]="!permissionService.tasks.edit ? 'noEditPermission' : ''">
                            <i class="far fa-check-square"></i>
                            Task Status :

                            <mat-menu #menu="matMenu">
                                <span *ngIf="this.permissionService.tasks.edit && taskDetails.inActiveStatus !== 1">
                                    <button
                                        *ngFor="let ts of taskStatus"
                                        mat-menu-item
                                        (click)="statusUpdate(ts.value, taskDetails['taskId'], taskDetails.ticketLockStatus)">
                                        {{ ts.label }}
                                    </button>
                                </span>
                            </mat-menu>
                            <span *ngIf="taskDetails['taskListStatus'] === 'IN PROGRESS'">
                                <a class="statusbtn" [matMenuTriggerFor]="menu">
                                    <div disabled class="tag-btn progressButton">
                                        <span class="status_string">{{ 'task.task-list.inprogress' | translate }}</span>
                                        <span
                                            *ngIf="this.permissionService.tasks.edit && taskDetails.inActiveStatus !== 1"
                                            class="caret"></span>
                                    </div>
                                </a>
                            </span>
                            <span *ngIf="taskDetails['taskListStatus'] === 'COMPLETED'">
                                <a class="statusbtn" [matMenuTriggerFor]="menu">
                                    <div disabled class="tag-btn successButton">
                                        <span class="status_string">{{ 'task.task-list.completed' | translate }}</span>
                                        <span
                                            *ngIf="this.permissionService.tasks.edit && taskDetails.inActiveStatus !== 1"
                                            class="caret"></span>
                                    </div>
                                </a>
                            </span>
                            <span *ngIf="taskDetails['taskListStatus'] === 'STARTED'">
                                <a class="statusbtn" [matMenuTriggerFor]="menu">
                                    <div disabled class="tag-btn voiletButton">
                                        <span class="status_string">{{ 'task.task-list.started' | translate }}</span>
                                        <span
                                            *ngIf="this.permissionService.tasks.edit && taskDetails.inActiveStatus !== 1"
                                            class="caret"></span>
                                    </div>
                                </a>
                            </span>
                            <span *ngIf="taskDetails['taskListStatus'] === 'CANCEL'">
                                <a class="statusbtn" [matMenuTriggerFor]="menu">
                                    <div disabled class="tag-btn dangerButton">
                                        <span class="status_string">{{ 'task.task-list.cancel' | translate }}</span>
                                        <span
                                            *ngIf="this.permissionService.tasks.edit && taskDetails.inActiveStatus !== 1"
                                            class="caret"></span>
                                    </div>
                                </a>
                            </span>
                            <span *ngIf="taskDetails['taskListStatus'] === 'NOT STARTED'">
                                <a class="statusbtn" [matMenuTriggerFor]="menu">
                                    <div disabled class="tag-btn warningButton">
                                        <span class="status_string">{{ 'task.task-list.notstarted' | translate }}</span>
                                        <span
                                            *ngIf="this.permissionService.tasks.edit && taskDetails.inActiveStatus !== 1"
                                            class="caret"></span>
                                    </div>
                                </a>
                            </span>
                            <span *ngIf="taskDetails['taskListStatus'] === 'WAITING FOR SOMEONE'">
                                <a class="statusbtn" [matMenuTriggerFor]="menu">
                                    <div disabled class="tag-btn btn_warning_to">
                                        <span class="status_string">{{ 'task.task-list.waiting' | translate }}</span>
                                        <span
                                            *ngIf="this.permissionService.tasks.edit && taskDetails.inActiveStatus !== 1"
                                            class="caret"></span>
                                    </div>
                                </a>
                            </span>
                            <span *ngIf="taskDetails['taskListStatus'] === 'ON HOLD'">
                                <a class="statusbtn" [matMenuTriggerFor]="menu">
                                    <div disabled class="tag-btn btn_deferrd">
                                        <span class="status_string">{{ 'task.task-list.onhold' | translate }}</span>
                                        <span
                                            *ngIf="this.permissionService.tasks.edit && taskDetails.inActiveStatus !== 1"
                                            class="caret"></span>
                                    </div>
                                </a>
                            </span>

                            <!-- <span class="tag-btn successButton" *ngIf="taskDetails['taskStatus'] == 'completed'">{{'task.task-list.completed'
                |
                translate }}</span>
              <span  class="tag-btn warningButton" *ngIf="taskDetails['taskStatus'] == 'not_started'"> {{'task.task-list.notstarted' | translate }}</span>
              <span class="tag-btn progressButton" *ngIf="taskDetails['taskStatus'] == 'in_progress'">{{'task.task-list.inprogress'
                | translate }}</span>
              <span class="tag-btn voiletButton" *ngIf="taskDetails['taskStatus'] == 'started'">{{'task.task-list.started'
                | translate }}</span>
              <span class="tag-btn dangerButton" *ngIf="taskDetails['taskStatus'] == 'cancel'">{{'task.task-list.cancel'
                | translate }}</span>
              <span class="tag-btn btn_deferrd" *ngIf="taskDetails['taskStatus'] == 'on_hold'"> On Hold </span>
              <span class="tag-btn btn_warning_to" *ngIf="taskDetails['taskStatus'] == 'waiting_for_someone'">Waiting for
                someone </span>
                <a class="btn btn-sm btn-action statusbtn" [matMenuTriggerFor]="menu" >
               <span class="btn btn-success">Change Status
                 <span class="caret"></span></span>
             </a>
                <mat-menu #menu="matMenu" >
                      <button mat-menu-item *ngFor="let ts of taskStatus" (click)="statusUpdate(ts.value,taskDetails['taskId'],taskDetails.ticketLockStatus)">{{ts.label}}</button>
                </mat-menu> -->
                        </div>
                    </ng-container>
                    <div class="col-md-5">
                        <i class="fa fa-clock-o"></i>
                        Estimated Hours :
                        <span class="text-bold section_lbl">
                            {{ taskDetails['taskTimeDetail'] }}
                        </span>
                    </div>
                </div>
                <hr />
                <div class="row padding-y">
                    <div class="col-md-7">
                        <i class="fa fa-calendar-alt"></i>
                        Started On :
                        <span class="text-bold section_lbl inline_label">
                            {{ taskDetails['taskStartDate'] | commonDate: 'date' }}
                            {{ taskDetails['taskStartDate'] | commonDate: 'short_time_format' }}
                        </span>
                    </div>
                    <div class="col-md-5">
                        <i class="fas fa-exclamation-circle mat-icon"></i>
                        Task Priority :
                        <span class="text-bold section_lbl inline_label">
                            <span class="font-medium">
                                <span *ngIf="taskDetails['taskPriority'] === 'low'" style="color: #00b050">
                                    {{ taskDetails['taskPriority'] | titlecase }}
                                </span>
                                <span *ngIf="taskDetails['taskPriority'] === 'medium'" style="color: #ff9900">
                                    {{ taskDetails['taskPriority'] | titlecase }}
                                </span>
                                <span *ngIf="taskDetails['taskPriority'] === 'high'" style="color: #ff0000">
                                    {{ taskDetails['taskPriority'] | titlecase }}
                                </span>
                                <span *ngIf="taskDetails['taskPriority'] === 'urgent'" style="color: #9400d3">
                                    {{ taskDetails['taskPriority'] | titlecase }}
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-4 border-R border-L">
                <div class="circle_bar">
                    <circle-progress
                        [percent]="taskDetails['taskProgress']"
                        [radius]="60"
                        [innerStrokeWidth]="6"
                        [outerStrokeWidth]="4"
                        [innerStrokeColor]="'#ffffff'"
                        [showZeroOuterStroke]="false"
                        [animation]="true"
                        [animationDuration]="200"
                        class="tw-flex tw-justify-center"></circle-progress>
                </div>
                <div *ngIf="show" class="time-clock">
                    <div class="clock-digit">{{ totalCalculatedhours }}</div>
                    <div class="clock-digit">{{ totalCalculatedminutes }}</div>
                    <div class="clock-digit">{{ totalCalculatedseconds }}</div>
                    <div class="bill-amount">
                        {{ billAmount | number: '1.2-2' | commonDate: 'money_format' | commonDate: 'currency_format' }}
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="task_description_row">
            <div class="col-md-12">
                <span><b>Task Description:</b></span>
            </div>
        </div>
        <div class="panel-body">
            <span class="note-data ql-editor tinyClass" [innerHTML]="taskDetails['taskDescription'] || '' | safe: 'html'"></span>
        </div>
        <hr />
        <div *ngIf="customFields" class="col-md-12 col-sm-12 clearfix">
            <div class="detail_section second clearfix">
                <div class="m-b-5 pull-left">
                    <i class="far fa-star mat-icon"></i>
                </div>
                <div class="clearfix">Custom Fields :</div>
                <div *ngFor="let field of customElements; let even = even; let odd = odd">
                    <div *ngIf="odd" class="col-md-6 custom-field-design">
                        <div class="clearfix">
                            <div class="col-md-5 col-sm-5 col-xs-5">
                                <div>
                                    <i class="far fa-circle"></i>
                                    <span class="left_labels">{{ field.fieldLabel }} :</span>
                                </div>
                            </div>
                            <div class="col-md-7 col-sm-7 col-xs-7 txtWrap">
                                <div>
                                    <span>
                                        <strong>{{ field.fieldValue }}</strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="even" class="col-md-6 custom-field-design">
                        <div class="clearfix">
                            <div class="col-md-5 col-sm-5 col-xs-5">
                                <div>
                                    <i class="far fa-circle"></i>
                                    <span class="left_labels">{{ field.fieldLabel }} :</span>
                                </div>
                            </div>
                            <div class="col-md-7 col-sm-7 col-xs-7 txtWrap">
                                <div>
                                    <span>
                                        <strong>{{ field.fieldValue }}</strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
