import { Component, Inject, Injector, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup, MatTab, MatTabLabel, MatTabContent } from '@angular/material/tabs';
import { AppComponentBase } from '../../shared/AppComponentBase';
import { EStatusCode, IResponse, AlertCommonDialogData } from '../../../service/Utils/Interfaces.class';
import { PermissionDialogComponent } from '../../shared/permission-dialog/permission-dialog.component';
import {COMMON} from "../../../service/constant";
import { CommonDialogModelComponent } from '../../shared/common-dialog-model/common-dialog-model.component';
import { Subscription } from 'rxjs';
import { TaskService } from '../../../service/task.service';
import { ClientService } from '../../../service/client.service';
import { ProjectService } from '../../../service/project.service';
import { TicketsService } from '../../../service/tickets.service';
import { OpportunitiesService } from '../../../service/opportunities.service';
import { LeadsService } from '../../../service/leads.service';
import { BugsService } from '../../../service/bugs.service';
import { DashboardService } from '../../../service/dashboard.service';
import { AttendanceReportService } from '../../../service/attendance-report.service';
import {PinactionService} from "../../../service/pinaction.service";
import { ScrollTopButtonComponent } from '../../shared/scroll-top-button/scroll-top-button.component';
import { CommonAuditTrialPopupComponent } from '../../shared/common-audit-trial-popup/common-audit-trial-popup.component';
import { TaskTimesheetComponent } from './task-timesheet/task-timesheet.component';
import { TaskCommentComponent } from '../../shared/task-comment/task-comment.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatTooltip } from '@angular/material/tooltip';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { TaskViewDetailComponent } from './task-view-detail/task-view-detail.component';
import { TaskAttachmentsComponent } from './task-attachments/task-attachments.component';
import { TaskNotesComponent } from './task-notes/task-notes.component';

@Component({
    selector: 'app-task-detail',
    templateUrl: './task-detail.component.html',
    styleUrls: ['./task-detail.component.scss'],
    standalone: true,
  imports: [MatButton, MatIcon, NgIf, MatTooltip, NgClass, ExtendedModule, MatTabGroup, MatTab, MatTabLabel, MatTabContent, TaskCommentComponent, TaskTimesheetComponent, CommonAuditTrialPopupComponent, NgFor, ScrollTopButtonComponent, TaskViewDetailComponent, TaskAttachmentsComponent, TaskNotesComponent, CommonAuditTrialPopupComponent, ScrollTopButtonComponent, TaskAttachmentsComponent, TaskCommentComponent, TaskNotesComponent, TaskTimesheetComponent, TaskViewDetailComponent]
})
export class TaskDetailComponent extends AppComponentBase implements OnInit {
  private readonly subscriptions: Subscription[] = [];
  public taskCount: string;
  public taskId: number;
  pinStatus: number;
  public inActiveStatus: number;
  activeTimerList: [];
  viewType: string;
  moduleId:  any;
  backFlag:boolean = false;
  @Output() calculateCount = new EventEmitter();
  @Output() calculateLeadCount = new EventEmitter();
  activeTimersCount: number;
  lockStatus: number;
  constructor(inject: Injector, public dialogRef: MatDialogRef<TaskDetailComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  public taskService: TaskService,
  public clientService: ClientService,private pinactionService: PinactionService,
  public projectService: ProjectService,
  public ticketsService: TicketsService,
  public opportunitiesService: OpportunitiesService,
  public leadsService: LeadsService,
  public bugsService: BugsService,
  public dashboardService: DashboardService,
  public attendanceReportService: AttendanceReportService) {
    super(inject);
    this.setWindowTitle('Task');
  }

  isPinned = 'no';
  public taskDeatails : any = [];
  dataForPermission : any;
  public activated: number;

  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;

  ngOnInit() {
    // this.taskId = this.data.taskId;
    if (this.data.hasOwnProperty('taskId')) {
      this.taskId = parseInt(this.data.taskId);
    } else {
      this.taskId = parseInt(this.data);
    }
    this.getTaskDetails();
    this.getTaskCount();
    this.toastr.warning("Please do not forget to start / stop timer");
    this.backPageFromDetail();
  }

  ngOnDestroy() {
    this.taskService.viewType = null;
    this.taskService.moduleId = null;
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }

  getTaskCount(){
    const getTaskDet : Subscription = this.taskService.getTaskDetailCount(this.taskId).subscribe((res: IResponse) => {
      if (res.info.code === EStatusCode.OK) {
        this.taskCount = res.data[0];
      }
    });
    this.subscriptions.push(getTaskDet);
  }
  getTaskDetails() {
    const leadesListdetail : Subscription = this.taskService.detailsById(this.taskId).subscribe((res: IResponse) => {
      if (res.info.code == EStatusCode.OK) {
        this.pinStatus = res.data[0].isPinned;
        this.taskDeatails = res.data[0];
        this.inActiveStatus = (this.taskDeatails.inActiveStatus !== 1 && this.taskDeatails.clientIsDeleted !== 1) ? 0 : 1;
        this.activated = (this.taskDeatails.activated !== 1 || this.taskDeatails.relatedActivate !== 1) ? 0 : 1;
      } else {
        this.activated = 1;
        this.dialogRef.close();
        this.routerDialog.navigateByUrl('admin/tasks');
        this.toastr.error(COMMON.VIEW_PERMISSION_ERROR);
      }
      this.dataForPermission = {
        "taskId": this.taskId,
        "taskName": this.taskDeatails.taskName,
        "permission": this.taskDeatails.permission,
        "permissionType": this.taskDeatails.permissionType,
        "customUsers": this.taskDeatails.customUsers,
        "deptId": this.taskDeatails.deptId,
        "users": this.taskDeatails.users,
        "responsibleUserId" : this.taskDeatails.responsibleUserId
      };
    })
    this.subscriptions.push(leadesListdetail);

  }

  navigateTaskMainPage() {
    var taskData;
    if (this.data.type === 'dashboard') {
      taskData = {
        taskId: this.taskId,
        msg: 'dashboard'
      };
    }
    else if (this.data.type === 'calendar') {
      taskData = {
        taskId: this.taskId,
        msg: 'calendar'
      };
    } else {
      taskData = {
        taskId: this.taskId,
        msg: 'list'
      };
    }

    this.dialogRef.close(taskData);
  }

  editTask(taskId, ticketLockStatus) {
    if (ticketLockStatus === 1) {
      this.toastr.error('Ticket has been locked and no changes can be made. Please unlock the ticket first');
    } else {
      var taskData = {
        taskId: this.taskId,
        msg: "edit"
      };
      this.dialogRef.close(taskData);

    }
  }

  addTodo() {
    const addAPin : Subscription = this.pinactionService.addPin('tasks', this.taskId).subscribe((pinaction: any) => {
      if (pinaction.result.info.code === EStatusCode.OK) {
        this.getTaskCount();
        this.pinStatus = 1;
        this.toastr.success('Task pinned successfully');
      } else {
        this.toastr.warning('Something went wrong!');
      }
    });
    this.subscriptions.push(addAPin);
  }

  removeTodo() {
    const removeAPin : Subscription = this.pinactionService.removePin('tasks', this.taskId).subscribe((pinaction: any) => {
      if (pinaction.result.info.code === EStatusCode.OK) {
        this.getTaskCount();
        this.pinStatus = 0;
        this.toastr.success('Task unpinned successfully');
      } else {
        this.toastr.warning('Something went wrong!');
      }
    });
    this.subscriptions.push(removeAPin);
  }
  delTask(taskId, ticketLockStatus) {
    /* getting active timer details */
    const getActiveTim : Subscription = this.taskService.getActiveTimerCount('task', taskId).subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        this.activeTimersCount = res.data.activeTimerCount;
        this.activeTimerList = res.data.activeTimerList;
      } else {
        this.activeTimersCount = 0;
        this.activeTimerList = [];
      }
      if ((this.viewType == 'tickets' && this.lockStatus === 1) || ticketLockStatus === 1) {
        this.toastr.error('Ticket has been locked and no changes can be made. Please unlock the ticket first');
      } else {
        let alertCommonDialogData: AlertCommonDialogData = {
          title: '',
          message: '',
          submitButtonText: '',
          cancelButtonText: ''
        };
        if (this.activeTimersCount === 0) {
          alertCommonDialogData.title = 'Confirm Delete';
          alertCommonDialogData.message = 'You are about to delete a record. This cannot be undone. Are you sure?';
          alertCommonDialogData.submitButtonText = 'Confirm';
          alertCommonDialogData.cancelButtonText = 'Cancel';
          alertCommonDialogData['activeTimersCount'] = 0;
          alertCommonDialogData['activeTimerList'] = [];
        }
        else {
          alertCommonDialogData.title = 'Confirm Delete';
          alertCommonDialogData.message = 'You are about to delete a record, timer running will be stopped. This cannot be undone. Are you sure?';
          alertCommonDialogData.submitButtonText = 'Stop timer & delete';
          alertCommonDialogData.cancelButtonText = 'Cancel';
          alertCommonDialogData['activeTimersCount'] = this.activeTimersCount;
          alertCommonDialogData['activeTimerList'] = this.activeTimerList;
        }
        const dialogRef = this.dialog.open(CommonDialogModelComponent, {
          width: COMMON.DELETE_ALERT_WIDTH,
          height: (this.activeTimersCount) ? COMMON.DELETE_ALERT_WITH_TIMER_HEIGHT : COMMON.DELETE_ALERT_HEIGHT,
          data: alertCommonDialogData
        });
        dialogRef.afterClosed().subscribe((res: boolean) => {
          if (res) {
            this.taskService.deletedTask(taskId).subscribe(taskRes => {
              if (taskRes) {
                this.toastr.success('Task Deleted Successfully!');

                const taskData = {
                  taskId: this.taskId,
                  msg: "list"
                };
                this.dialogRef.close(taskData);
              }
            }, (error) => {
            });
          }
        });
      }
    });
    this.subscriptions.push(getActiveTim);
  }

  openParticipantsDialog(data, ticketLockStatus) {
    if (ticketLockStatus === 1) {
      this.toastr.error('Ticket has been locked and no changes can be made. Please unlock the ticket first');
    } else {
      var matDialogConfig: MatDialogConfig = {
        position: {
          top: '50px',
        },
        width: '800px',
        panelClass: ['animated', 'none', this.configService.userTheme],
        backdropClass: 'participantspopup',
      };
      const dialogConfig = matDialogConfig;
      dialogConfig.data = {details: data, type: "tasks"};
      const dialogRef = this.dialog.open(PermissionDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result.data === "true") {
          this.getTaskCount();
          this.getTaskDetails();
        }
      });
    }
  }

  backToLastPage(){
    this.dialog.closeAll();
    if (this.viewType === 'project') {
      this.projectService.closedDetailPage = 'tasks';
      this.routerDialog.navigateByUrl('support/project/details/' + this.moduleId);
    } else if (this.viewType === 'client') {
      this.clientService.closedDetailPage = 'tasks';
      this.routerDialog.navigateByUrl('admin/client/details/' + this.moduleId);
    } else if (this.viewType === 'tickets') {
      this.ticketsService.closedDetailPage = 'tasks';
      this.routerDialog.navigateByUrl('support/tickets/details/' + this.moduleId);
    } else if (this.viewType === 'opportunities') {
      this.opportunitiesService.closedDetailPage = 'tasks';
      this.routerDialog.navigateByUrl('/sales/opportunities/details/' + this.moduleId);
    } else if (this.viewType === 'leads' || this.viewType === 'Leads') {
      this.leadsService.closedDetailPage = 'tasks';
      this.routerDialog.navigateByUrl('sales/leads/details/' + this.moduleId.leadsId);
    } else if (this.viewType === 'bugs') {
      this.bugsService.closedDetailPage = 'tasks';
      this.routerDialog.navigateByUrl('support/bugs/details/' + this.moduleId);
    } else if (this.viewType === 'overdue') {
      this.routerDialog.navigateByUrl('admin/dashboard');
    } else if (this.viewType === 'calendar') {
      this.routerDialog.navigateByUrl('admin/calendar');
    } else if (this.viewType === 'dashboard') {
      this.dashboardService.closedDetailPage = 'task';
      this.routerDialog.navigateByUrl('admin/dashboard');
    } else if (this.viewType === 'workHistory') {
      this.attendanceReportService.closedDetailPage = 'task';
      this.routerDialog.navigateByUrl(this.attendanceReportService.currentUrl);
    }

  }

  backPageFromDetail() {
    this.viewType = this.taskService.viewType;
    this.moduleId = this.taskService.moduleId;
    if (this.data.type === 'dashboard') {
      this.viewType = 'dashboard';
    } else if (this.data.type === 'calendar') {
      this.viewType = 'calendar';
    }
    if (this.viewType) {
      this.backFlag = true;
    }
  }
  goToCurrentList() {
    this.dialog.closeAll();
    this.routerDialog.navigateByUrl( '/admin/tasks' );
  }
}

