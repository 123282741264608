import {Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EStatusCode, IResponse} from '../../../../service/Utils/Interfaces.class';
import {AppComponentBase} from '../../../shared/AppComponentBase';
import {interval, Subscription} from 'rxjs';
import {COMMON, SOCKET_KEY, TIMERVALUES} from '../../../../service/constant';
import { TaskService } from '../../../../service/task.service';
import { DashboardService } from '../../../../service/dashboard.service';
import { TicketsService } from '../../../../service/tickets.service';
import { LeadsService } from '../../../../service/leads.service';
import { ClientService } from '../../../../service/client.service';
import { BugsService } from '../../../../service/bugs.service';
import { ProjectService } from '../../../../service/project.service';
import { OpportunitiesService } from '../../../../service/opportunities.service';
import moment from 'moment';
import * as momenttimezone from 'moment-timezone';
import { SocketService } from '../../../../service/socket.service';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipe } from '../../../shared/pipes/safe.pipe';
import { CommonDatePipe } from '../../../shared/pipes/common-date.pipe';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgIf, NgClass, NgFor, DecimalPipe, TitleCasePipe } from '@angular/common';

@Component({
    selector: 'app-task-view-detail',
    templateUrl: './task-view-detail.component.html',
    styleUrls: ['./task-view-detail.component.scss'],
    standalone: true,
  imports: [NgIf, NgClass, ExtendedModule, MatMenu, NgFor, MatMenuItem, MatMenuTrigger, NgCircleProgressModule, DecimalPipe, TitleCasePipe, CommonDatePipe, SafePipe, TranslateModule, CommonDatePipe, SafePipe]
})
export class TaskViewDetailComponent extends AppComponentBase implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  @Input() taskId: number;
  @Output() calculateCount = new EventEmitter<void>();
  @Input() viewType: string;
  @Input() moduleType: string;

  public activated: number;

  detailSub: Subscription[] = [];

  constructor(
    private injector: Injector,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TaskViewDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public taskService: TaskService, public dashboardService: DashboardService,
    public ticketsService: TicketsService, public leadsService: LeadsService, public clientService: ClientService, public bugsService: BugsService,
    private socketService: SocketService,
    public projectService: ProjectService, public opportunitiesService: OpportunitiesService
  ) {super(injector);

  // Sync timer for all active clients
    const floatingTimerSub =
    this.socketService.listen(SOCKET_KEY.TIMERSTART).subscribe((val) => {
      if (val && val.type === TIMERVALUES.task && val.id === this.taskId) {
        this.getTimerStatus(this.taskId);
        this.clockTimerStatus = 'off';
        this.totalTime(this.taskId);
      }
    });

  }

  public taskDetails: [];
  public taskClientDetails;
  totalCalculatedhours = 0;
  totalCalculatedminutes = 0;
  totalCalculatedseconds = 0;
  isEditPermission: boolean= this.permissionService.tasksDetails.edit;
  public statusComplete = false;
  public show = true;
  showClientField = false;
  taskStatus = [
    {
      'value': 'in_progress',
      'label': 'In Progress',
    },
    {
      'value': 'not_started',
      'label': 'Not Started'
    },
    {
      'value': 'completed',
      'label': 'Completed'
    },
    {
      'value': 'on_hold',
      'label': 'On Hold'
    },
    {
      'value': 'waiting_for_someone',
      'label': 'Waiting for Someone'
    },
    {
      'value': 'cancel',
      'label': 'Cancel'
    }
  ];
  map: google.maps.Map;
  address: string;
  taskstime = 0;
  clockTimerStatus: any = 'off';
  timerClockId: any;
  public play: boolean;
  private clear: boolean;
  start = 0;
  ticks = 0;

  timerDisplay = 0;

  num = 0;
  isClient = false;
  billAmount = 0;
  public customFields: boolean;
  public fields = [];
  public customElements = [];
  stopTimersSocket = false;
  loginDevice: Number = 0;

  ngOnInit() {

    this.isClient = false;
    this.taskId = this.data.taskId;
    this.detailsById(this.taskId);
    this.getTimerStatus(this.taskId);
    this.totalTime(this.taskId);
    this.getBillAmount();
    const mapProp = {
      center: new google.maps.LatLng(18.5793, 73.8143),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
  }

  ngOnDestroy() {
    this.stopTimers();
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  statusUpdate(taskStatus, taskId, ticketLockStatus) {
    if (ticketLockStatus === 1) {
      this.toastr.error('Ticket has been locked and no changes can be made. Please unlock the ticket first');
    } else if (taskStatus !== this.taskDetails['taskStatus']) {
      const data = {
        'taskId': taskId,
        'taskStatus': taskStatus
      };
      const setStatusUp: Subscription = this.taskService.setStatusUpdate(data).subscribe(res => {
        if (res['info'].code === EStatusCode.OK && res['data'].count === 1) {
          this.calculateCount.next();
          this.dashboardService.headerNotificationEventListener.next(true);
          if (res['data'].stopTimer === 1) {
            this.statusComplete = true;
            this.clockTimerStatus = 'off';
            this.show = true;
            this.totalTime(this.taskId);
            this.stopTimers();
          }else {
            this.statusComplete = false;
          }
          this.detailsById(this.taskId);
        }

      });
      this.subscriptions.push(setStatusUp);
    }
  }

  startTimer(taskData) {
    this.stopTimersSocket = false;
    let relatedTo, relatedId;
    if (taskData.projectId) {
      relatedTo = 'project';
      relatedId = taskData.projectId;
    } else if (taskData.ticketsId) {
      relatedTo = 'ticket';
      relatedId = taskData.ticketsId;
    }
    if (taskData.ticketLockStatus === 1) {
      this.toastr.error('Ticket has been locked and no changes can be made. Please unlock the ticket first');
    } else {
      this.clear = true;
      const taskATimer: Subscription = this.taskService.taskTimer('on', 'task', taskData.taskId, this.loginDevice, relatedTo, relatedId).subscribe((timerRes: any) => {
        if (timerRes.result.info.code === EStatusCode.OK) {

          this.calculateCount.next();

          this.startTimers();

          this.taskstime = timerRes.result.data.taskId;
          this.clockTimerStatus = timerRes.result.data.clockTimerStatus;
          this.timerClockId = timerRes.result.data.timerClockinClockoutId;

          if (timerRes.result.data.isAutoClockIn === 1) {
            this.toastr.success(COMMON.AUTO_CLOCK_IN);
          }
        }
      });
      this.subscriptions.push(taskATimer);
    }
  }

  stopTimer(timerId, taskId, ticketLockStatus) {

    if (timerId) {
    if (ticketLockStatus === 1) {
      this.toastr.error('Ticket has been locked and no changes can be made. Please unlock the ticket first');
    } else {
      const taskTimerOnOff: Subscription = this.taskService.taskTimerOff('off', 'task', this.taskId, timerId, this.loginDevice).subscribe((timerOffRes: any) => {
        if (timerOffRes.result.info.code === EStatusCode.OK) {
          this.clockTimerStatus = 'off';
          this.calculateCount.next();
          this.show = true;
          this.totalTime(this.taskId);
          this.stopTimers();
        }
      });
      this.subscriptions.push(taskTimerOnOff);
    }
  }
  }


  private startTimers() {
    const TaskkdetailsById: Subscription = this.taskService.detailsById(this.taskId).subscribe((res: IResponse) => {

      if (res.info.code === EStatusCode.OK) {
        this.stopTimers();
        const subscription = interval(1000).subscribe(t => {

          this.ticks = t;

          const dateIn = new Date();
          const time = Math.round(dateIn.getTime() / 1000);

          this.timerDisplay = time - Number(res.data[0].startTime);
          this.timerClockId = res.data[0].clockTimerId;


          // update startTime on 12 AM
          const timeZone = this.configService.timezone;
          const currentTime = moment().utcOffset(momenttimezone.tz(moment(), timeZone).format('Z')).format('HH:mm:ss');

          const nextDate = moment(momenttimezone.tz((res.data[0].startTime * 1000), timeZone)).add(1, 'd').format('YYYY-MM-DD');
          const newStartTime = moment(momenttimezone.tz(nextDate + ' 00:00:00', timeZone).utc().format('YYYY-MM-DD' +
            ' HH:mm:ss')).unix();

          if (currentTime === '00:00:00') {
            res.data[0].startTime = newStartTime;
          }

          if (currentTime === '00:02:00') {
            // Refresh timer list

            this.startTimers();
            this.calculateCount.next();
          }

        });
        this.detailSub.push(subscription);
      }
    });
    this.subscriptions.push(TaskkdetailsById);

  }

  private stopTimers() {

    if (this.detailSub && this.detailSub.length) {
      this.detailSub.forEach(element => {
        element.unsubscribe();
      });
      this.detailSub = [];
    }
    this.timerDisplay = 0;
  }

  detailsById(taskId) {
    const TaskAKdetailsById: Subscription = this.taskService.detailsById(taskId).subscribe((res: IResponse) => {
      if (res.info.code === EStatusCode.OK) {

        this.taskDetails = res.data[0];
        this.taskDetails['taskTimeDetail'] = this.timeValueWithWords(this.taskDetails['taskHour']);
        if (this.taskDetails['clocktimerStatus'] === 'on') {
          this.startTimers();
        }
        this.activated = (this.taskDetails['activated'] !== 1 || this.taskDetails['relatedActivate'] !== 1) ? 0 : 1;
        if (this.taskDetails['taskbugId'] || this.taskDetails['clientId'] || this.taskDetails['goalTrackingId'] || this.taskDetails['leadsId']  ||
          this.taskDetails['bugId'] ||
          this.taskDetails['milestonesId'] || this.taskDetails['opportunitiesId'] || this.taskDetails['projectId'] || this.taskDetails['ticketsId']) {
          this.showClientField = true;
        }
        this.fields = res.data[0].customFieldData;
        this.customElements =  this.getCustomFieldValueList(this.fields);
        this.customFields = !!(this.customElements.length);
        this.statusComplete = this.taskDetails['taskStatus'] === 'completed';

      } else {
        this.activated = 1;
      }
    });
    this.subscriptions.push(TaskAKdetailsById);
    const taskAClientName: Subscription = this.taskService.taskClientName(taskId).subscribe((resTaskRelatedTo: IResponse) => {
      if (resTaskRelatedTo && resTaskRelatedTo.info && resTaskRelatedTo.info.code === EStatusCode.OK) {
        this.taskClientDetails = resTaskRelatedTo.data[0];
         if (this.taskClientDetails) {
          this.isClient = true;
         }
      }

    });
    this.subscriptions.push(taskAClientName);
  }

  getTimerStatus(taskId) {
    const timerStatusAName: Subscription = this.taskService.timerStatus(taskId).subscribe((tres: IResponse) => {
      if (tres.info.code === EStatusCode.OK) {
        if (tres.data['responseMsg'] === 'No Record found!') {
        } else {
          this.clockTimerStatus = tres.data[0].clockTimerStatus;
          this.timerClockId = tres.data[0].timerClockinClockoutId;
        }
      }
    });
    this.subscriptions.push(timerStatusAName);
  }

  totalTime(taskId) {
    const timertotalTimeById: Subscription = this.taskService.totalTimeById(taskId).subscribe((res: IResponse) => {
      if (res.info.code === EStatusCode.OK) {
        this.totalCalculatedhours = res.data.hours;
        this.totalCalculatedminutes = res.data.minutes;
        this.totalCalculatedseconds = res.data.seconds;
      }
    });
    this.subscriptions.push(timertotalTimeById);
  }
  closeRefModal() {
    this.dialogRef.close(this.data);
  }
  viewdetails(id, type, inActiveStatus, $event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    if (type === 'tickets') {
      if (inActiveStatus === 1) {
       this.toastr.error('Ticket has been deleted, you are unable to make any changes.');
      } else {
        this.ticketsService.viewType = 'taskDetail';
        this.ticketsService.moduleId = this.taskId;
        this.dialogRef.close();
      this.routerDialog.navigateByUrl('support/tickets/details/' + id);
      }
    } else if (type === 'leads') {
      if (inActiveStatus === 1) {
        this.toastr.error('Lead has been deleted, you are unable to make any changes.');
      } else {
        this.leadsService.viewType = 'taskDetail';
        this.leadsService.moduleId = this.taskId;
        this.dialogRef.close();
      this.routerDialog.navigateByUrl('sales/leads/details/' + id);
      }
    }else if (type === 'client') {
      if (inActiveStatus === 1) {
        this.toastr.error('Client is inactive or has been deleted, you are unable to make any changes, pls restore the Client first');
      } else {
        this.clientService.viewType = 'taskDetail';
        this.clientService.moduleId = this.taskId;
        this.dialogRef.close();
      this.clientService.clientDetail.msg = 'task';
      this.routerDialog.navigateByUrl('admin/client/details/' + id);
      }
    }else if (type === 'opportunity') {
      if (inActiveStatus === 1) {
        this.toastr.error('Opportunity has been deleted, you are unable to make any changes.');
      } else {
        this.opportunitiesService.viewType = 'taskDetail';
        this.opportunitiesService.moduleId = this.taskId;
        this.dialogRef.close();
        this.routerDialog.navigateByUrl('sales/opportunities/details/' + id);
      }
    }else if (type === 'bugs') {
      if (inActiveStatus === 1) {
        this.toastr.error('Bug has been deleted, you are unable to make any changes.');
      } else {
        this.bugsService.bugViewType = 'taskDetail';
        this.bugsService.bugModuleId = this.taskId;
        this.dialogRef.close();
      this.routerDialog.navigateByUrl('support/bugs/details/' + id);
      }
    }else if (type === 'project') {
      if (inActiveStatus === 1) {
        this.toastr.error('Project has been deleted, you are unable to make any changes.');
      } else {
        this.projectService.viewType = 'taskDetail';
        this.projectService.moduleId = this.taskId;
        this.dialogRef.close();
      this.routerDialog.navigateByUrl('support/project/details/' + id);
      }
    }

  }
  getBillAmount() {
    const timertotgetBillAmount: Subscription = this.projectService.getBillAmount(this.taskId, 'task').subscribe((res: IResponse) => {
      if (res.info.code === EStatusCode.OK) {
        this.billAmount = res.data.billAmount.toFixed(2);
      } else {
        this.billAmount = 0;
      }
    });
    this.subscriptions.push(timertotgetBillAmount);
  }

  timeValueWithWords(timeData) {
    let hour = '', minutes = '', timeTot = '';
    if (timeData > 0) {
      timeData = timeData.toString().split('.');
      hour = (timeData[0].length > 1) ? (timeData[0] + ' Hours ') : (( timeData[0] === '1' ) ? (timeData[0] + ' Hour ') : ( timeData[0] > 1 ? (timeData[0] + ' Hours ') : ''));
      minutes = !timeData[1] ? '' : (timeData[1] > 1) ? (timeData[1] + ' minutes') : '';
      timeTot = hour + minutes;
    }
    return timeTot;
  }


}

