import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AttachmentFormComponent } from '../../../shared/bugs-attachment/attachment-form/attachment-form.component';
import { NgIf } from '@angular/common';
import { AttachmentsListComponent } from '../../../shared/bugs-attachment/attachments-list/attachments-list.component';
import { MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';

@Component({
    selector: 'app-task-attachments',
    templateUrl: './task-attachments.component.html',
    styleUrls: ['./task-attachments.component.scss'],
    standalone: true,
    imports: [MatTabGroup, MatTab, MatTabContent, AttachmentsListComponent, NgIf, AttachmentFormComponent]
})
export class TaskAttachmentsComponent implements OnInit {
  @Input() taskId: number;
  @Input() isClientActive: number;
  @Input() activated: number;
  @Input() viewType:string;
  isChanged: boolean;
 @Output() taskDetailCount = new EventEmitter<void>();
  @Input() ticketLockStatus: number;
  constructor() { }

  ngOnInit() {
    this.isChanged = false;
  }

  isTabChange(tab?) {
    this.isChanged = tab;
  }

  getTaskAttachCount(){
    this.taskDetailCount.next();
  }
  onLinkClick(event) {
    if (event.index === 0) {
      this.isChanged = false;
    }
  }

}
