<div id="taskDetail" class="taskDetail" #adminDev>
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <!-- <button name="backBtn" mat-button (click)="navigateTaskMainPage()" color="primary">
      <mat-icon>keyboard_arrow_left</mat-icon>
      <span *ngIf="data.type !== 'dashboard'&& data.type !== 'calendar'"> Back to Task List</span>
      <span *ngIf="data.type === 'dashboard'"> Back to Dashboard</span>
      <span *ngIf="data.type === 'calendar'"> Back to Calendar</span> -->
        <button name="backBtn" mat-button color="primary" (click)="goToCurrentList()">
            <mat-icon>keyboard_arrow_left</mat-icon>
            <span>Back to Task List</span>
        </button>

        <button
            *ngIf="this.backFlag"
            name="cross"
            mat-button
            style="float: right"
            color="primary"
            matTooltip="Close"
            matTooltipPosition="right">
            <a (click)="backToLastPage()">
                <mat-icon>clear</mat-icon>
            </a>
        </button>
    </section>

    <section class="content">
        <div *ngIf="true">
            <a
                *ngIf="permissionService.tasks.edit"
                class="btn custom-item-btn"
                [ngClass]="{ disabled: taskDeatails.inActiveStatus === 1 || taskDeatails.clientIsDeleted === 1 || activated === 0 }"
                (click)="editTask(taskId, taskDeatails.ticketLockStatus)">
                <i class="far fa-edit edit-icon"></i>
            </a>
            <a
                *ngIf="pinStatus == 0 && permissionService.tasks.edit"
                class="btn custom-item-btn"
                title="To do"
                data-toggle="tooltip"
                data-placement="top"
                [ngClass]="{ disabled: taskDeatails.inActiveStatus === 1 || taskDeatails.clientIsDeleted === 1 || activated === 0 }"
                (click)="addTodo()"
                (calculateCount)="getTaskCount()">
                <i class="fa fa-thumb-tack text-warning" aria-hidden="true"></i>
            </a>
            <a
                *ngIf="pinStatus == 1 && permissionService.tasks.edit"
                class="btn custom-item-btn"
                title="To do"
                data-toggle="tooltip"
                data-placement="top"
                [ngClass]="{ disabled: taskDeatails.inActiveStatus === 1 || taskDeatails.clientIsDeleted === 1 || activated === 0 }"
                (click)="removeTodo()"
                (calculateCount)="getTaskCount()">
                <i class="fa fa-thumb-tack text-danger" aria-hidden="true"></i>
            </a>
            <a
                *ngIf="permissionService.tasks.edit"
                class="btn custom-item-btn"
                matTooltip="Delete"
                [ngClass]="{ disabled: taskDeatails.inActiveStatus === 1 || taskDeatails.clientIsDeleted === 1 || activated === 0 }"
                (click)="delTask(taskId, taskDeatails.ticketLockStatus)">
                <div class="ripple">
                    <i class="fai fa-trash-o text-danger fa-lg" aria-hidden="true"></i>
                </div>
            </a>
        </div>
        <div *ngIf="false">
            <a
                *ngIf="permissionService.tasks.edit"
                class="btn custom-item-btn"
                [ngClass]="{ disabled: taskDeatails.inActiveStatus === 1 }"
                (click)="editTask(taskId, taskDeatails.ticketLockStatus)">
                <i class="far fa-edit edit-icon"></i>
            </a>
            <a
                *ngIf="pinStatus == 0 && permissionService.tasks.edit"
                class="btn custom-item-btn"
                title="To do"
                data-toggle="tooltip"
                data-placement="top"
                [ngClass]="{ disabled: taskDeatails.inActiveStatus === 1 }"
                (click)="addTodo()"
                (calculateCount)="getTaskCount()">
                <i class="fa fa-thumb-tack text-warning" aria-hidden="true"></i>
            </a>
            <a
                *ngIf="pinStatus == 1 && permissionService.tasks.edit"
                class="btn custom-item-btn"
                title="To do"
                data-toggle="tooltip"
                data-placement="top"
                [ngClass]="{ disabled: taskDeatails.inActiveStatus === 1 }"
                (click)="removeTodo()"
                (calculateCount)="getTaskCount()">
                <i class="fa fa-thumb-tack text-danger" aria-hidden="true"></i>
            </a>
            <a
                *ngIf="permissionService.tasks.edit"
                class="btn custom-item-btn"
                matTooltip="Delete"
                [ngClass]="{ disabled: taskDeatails.inActiveStatus === 1 }"
                (click)="delTask(taskId, taskDeatails.ticketLockStatus)">
                <div class="ripple">
                    <i class="fai fa-trash-o text-danger fa-lg" aria-hidden="true"></i>
                </div>
            </a>
        </div>
        <div id="content">
            <div id="main-content">
                <mat-tab-group #tabGroup class="mat-tab-header-noborder">
                    <mat-tab *ngIf="permissionService.tasksDetails.view" label="Details">
                        <ng-template matTabContent>
                            <app-task-view-detail [taskId]="taskId" (calculateCount)="getTaskCount()"></app-task-view-detail>
                        </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="permissionService.tasksComments.view" label="Comments">
                        <ng-template mat-tab-label>
                            <span>Comments</span>
                            <span class="taskDetailCount">{{ taskCount?.comment ? taskCount?.comment : '' }}</span>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-task-comment
                                [moduleId]="taskId"
                                [activated]="activated"
                                [viewType]="'task'"
                                [isClientActive]="inActiveStatus"
                                [ticketLockStatus]="taskDeatails.ticketLockStatus"
                                (calculateCount)="getTaskCount()"></app-task-comment>
                        </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="permissionService.tasksAttachment.view" label="Attachments">
                        <ng-template mat-tab-label>
                            <span>Attachments</span>
                            <span class="taskDetailCount">{{ taskCount?.attachments ? taskCount?.attachments : '' }}</span>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-task-attachments
                                [taskId]="taskId"
                                [activated]="activated"
                                [viewType]="'task'"
                                [isClientActive]="inActiveStatus"
                                [ticketLockStatus]="taskDeatails.ticketLockStatus"
                                (taskDetailCount)="getTaskCount()"></app-task-attachments>
                        </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="permissionService.tasksNotes.view" label="Notes">
                        <ng-template mat-tab-label>
                            <span>Private Notes</span>
                            <span class="taskDetailCount">{{ taskCount?.notes ? taskCount?.notes : '' }}</span>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-task-notes
                                [taskId]="taskId"
                                [activated]="activated"
                                [isClientActive]="inActiveStatus"
                                (taskDetailCount)="getTaskCount()"></app-task-notes>
                        </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="permissionService.tasksTimesheet.view" label="Timesheet">
                        <ng-template mat-tab-label>
                            <span>Timesheet</span>
                            <span class="taskDetailCount">{{ taskCount?.timesheets ? taskCount?.timesheets : '' }}</span>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-task-timesheet
                                [taskId]="taskId"
                                [viewType]="'task'"
                                [isClientActive]="inActiveStatus"
                                [activated]="activated"
                                [ticketLockStatus]="taskDeatails.ticketLockStatus"
                                (calculateCount)="getTaskCount()"></app-task-timesheet>
                        </ng-template>
                    </mat-tab>
                    <!-- <mat-tab label="Tickets">
            <ng-template mat-tab-label>
              <span>Tickets</span>
              <span class="taskDetailCount">{{taskCount?.timesheets ? taskCount?.timesheets : ''}}</span>
            </ng-template>
            <ng-template matTabContent>
              <app-task-ticket [taskId]="taskId"></app-task-ticket>
            </ng-template>
          </mat-tab> -->
                    <!-- <mat-tab label="Activities" *ngIf="permissionService.tasksActivities.view">
            <ng-template mat-tab-label>
              <span>Activities</span>
              <span class="taskDetailCount">{{taskCount?.activity ? taskCount?.activity : ''}}</span>
            </ng-template>
            <ng-template matTabContent>
              <app-task-activities [taskId]="taskId"></app-task-activities>
            </ng-template>
          </mat-tab> -->
                    <mat-tab *ngIf="permissionService.tasksActivities.view">
                        <ng-template mat-tab-label>
                            <span>Activities</span>
                            <span class="taskDetailCount">{{ taskCount?.activity ? taskCount?.activity : '' }}</span>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-common-audit-trial-popup
                                [moduleType]="'tasks'"
                                [moduleId]="taskId"
                                [referenceCode]="dataForPermission?.taskName"></app-common-audit-trial-popup>
                        </ng-template>
                    </mat-tab>
                    <mat-tab class="task_participants" [disabled]="true">
                        <ng-template mat-tab-label class="task_participants">
                            <div class="content-left-area-tickets-single organization-details">
                                <h3 class="all-caps">Contributors</h3>
                                <p class="contributors_user mb-3">Responsible User</p>
                                <div
                                    *ngFor="let userDetails of taskDeatails.responsibileUserData; let i = index"
                                    class="row userImg mb-3"
                                    style="margin-left: auto">
                                    <span *ngIf="i <= 4">
                                        <span *ngIf="userDetails.activated == 1">
                                            <img

                                                src="{{ userDetails.avatar }}"
                                                alt="{{ userDetails.fullname }}"
                                                title="{{ userDetails.fullname }}"
                                                class="profile-pic mat-icon-button list-avatar-img"
                                                onError="this.src='assets/img/default_avatar.jpg'"
                                                (click)="$event.stopPropagation()" />
                                            <span
                                                style="margin: 0px 0 8px -10px"
                                                class="circle circle-success circle-lg"
                                                (click)="$event.stopPropagation()"></span>
                                        </span>
                                        <span *ngIf="userDetails.activated == 0">
                                            <img

                                                src="{{ userDetails.avatar }}"
                                                alt="{{ userDetails.fullname }}"
                                                title="{{ userDetails.fullname }}"
                                                class="profile-pic mat-icon-button list-avatar-img"
                                                onError="this.src='assets/img/default_avatar.jpg'"
                                                (click)="$event.stopPropagation()" />
                                            <span
                                                style="margin: 0px 0 8px -10px"
                                                class="circle circle-warning circle-lg"
                                                (click)="$event.stopPropagation()"></span>
                                        </span>
                                    </span>
                                </div>
                                <div class="content-left-area-tickets-single organization-details">
                                    <p class="contributors_user">Participants</p>
                                    <span *ngIf="taskDeatails.permissionType === 2" class="everyone_participants">
                                        Everyone
                                        <i class="fas fa-exclamation" matTooltip="Permission to all department Users"></i>
                                    </span>

                                    <div class="example-viewport-list cdk-list">
                                        <div
                                            *ngFor="let userDetails of taskDeatails.users; let i = index"
                                            class="row userImg user-list"
                                            (click)="$event.stopPropagation()">
                                            <span *ngIf="userDetails.activated == 1">
                                                <img

                                                    src="{{ userDetails.avatar }}"
                                                    alt="{{ userDetails.fullname }}"
                                                    title="{{ userDetails.fullname }}"
                                                    class="profile-pic mat-icon-button list-avatar-img"
                                                    onError="this.src='assets/img/default_avatar.jpg'" />
                                                <span style="margin: 0px 0 8px -10px" class="circle circle-success circle-lg"></span>
                                            </span>
                                            <span *ngIf="userDetails.activated == 0">
                                                <img

                                                    src="{{ userDetails.avatar }}"
                                                    alt="{{ userDetails.fullname }}"
                                                    title="{{ userDetails.fullname }}"
                                                    class="profile-pic mat-icon-button list-avatar-img"
                                                    onError="this.src='assets/img/default_avatar.jpg'" />
                                                <span style="margin: 0px 0 8px -10px" class="circle circle-warning circle-lg"></span>
                                            </span>
                                        </div>

                                        <!-- Plus icon div -->
                                        <div class="userImg user-list" style="margin-left: 5px">
                                            <a
                                                class="btn btn-sm btn-action plus_icon plus-btn"
                                                (click)="openParticipantsDialog(dataForPermission, taskDeatails.ticketLockStatus)">
                                                <div
                                                    *ngIf="permissionService.tasks.edit && taskDeatails.inActiveStatus !== 1"
                                                    class="ripple">
                                                    <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true" color="primary">
                                                        add
                                                    </mat-icon>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template matTabContent>
                            <app-task-view-detail [taskId]="taskId"></app-task-view-detail>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <!-- <div class="col-md-2 content-left-area-tickets-single organization-details">
        <h3 class="all-caps">Participants</h3>
        <span class="everyone_participants" *ngIf="taskDeatails.permission_type === 2">Everyone<i class="fas fa-exclamation" matTooltip="Permission to all department Users"></i>
        </span>
        <div class="row userImg" *ngFor="let userDetails of taskDeatails.users">
          <span *ngIf="userDetails.activated == 1">

            <img  src="assets/img/default_avatar.jpg" alt="{{userDetails.fullname}}" title="{{userDetails.fullname}}"
            class="profile-pic mat-icon-button list-avatar-img">
            <span style="margin: 0px 0 8px -10px;" class="circle circle-success circle-lg"></span>
          </span>
          <span *ngIf="userDetails.activated == 0">

            <img  src="assets/img/default_avatar.jpg" alt="{{userDetails.fullname}}" title="{{userDetails.fullname}}"
            class="profile-pic mat-icon-button list-avatar-img">
            <span style="margin: 0px 0 8px -10px;" class="circle circle-warning circle-lg"></span>
          </span>
        </div>
        <a class="btn btn-sm btn-action plus_icon"  (click)="openParticipantsDialog(dataForPermission)">
          <div class="ripple">
            <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true" color="primary" >add</mat-icon>
          </div>
        </a>

      </div> -->
        </div>
    </section>
    <app-scroll-top-button [scrollBody]="adminDev" [type]="'detail'"></app-scroll-top-button>
</div>
