import { Component, Input, OnInit, ViewChild, EventEmitter, Output, ElementRef, Injector } from '@angular/core';
import { NotesComponent } from '../../../shared/notes/notes.component';

@Component({
    selector: 'app-task-notes',
    templateUrl: './task-notes.component.html',
    styleUrls: ['./task-notes.component.scss'],
    standalone: true,
    imports: [NotesComponent]
})
export class TaskNotesComponent  implements OnInit {

  @Input() taskId: number;
  @Input() isClientActive: number;
  @Input() activated: number;
  @Output() taskDetailCount = new EventEmitter<void>();

  constructor() {
   
  }

  ngOnInit() {
  }

  calculateTaskCount() {
    this.taskDetailCount.next();
  }

}

