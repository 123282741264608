<div class="leadsOppoDetail taskAttachments">
  <div id="attachment-cont-dev">
    <mat-tab-group class="show-border" mat-stretch-tabs="false" mat-align-tabs="start" #tabGroup (selectedTabChange)="onLinkClick($event)">
      <mat-tab class="headerDiv" label="All Attachments">
        <ng-template matTabContent>
          <app-attachments-list [tabGroup]="tabGroup" (isUpdate)="isTabChange($event)" [ticketLockStatus]="ticketLockStatus"  [viewType]="'tasks'" [moduleId]="taskId" (calculateCount)="getTaskAttachCount()"
                                [activated]="activated"[isClientActive] = "isClientActive"></app-attachments-list>
        </ng-template>
      </mat-tab>
      <mat-tab class="headerDiv" label="New Attachment" *ngIf="!isChanged && isClientActive !== 1 && activated !== 0">
        <ng-template matTabContent>
          <app-attachment-form [tabGroup]="tabGroup" [viewType]="'tasks'" [moduleId]="taskId" [ticketLockStatus]="ticketLockStatus"(calculateCount)="getTaskAttachCount()"></app-attachment-form>
        </ng-template>
      </mat-tab>
      <mat-tab class="headerDiv" label="Update Attachment" *ngIf="isChanged && isClientActive !== 1 && activated !== 0">
        <ng-template matTabContent>
          <app-attachment-form [tabGroup]="tabGroup" [viewType]="'tasks'" [moduleId]="taskId" (isNavigate)="isTabChange($event)"></app-attachment-form>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
